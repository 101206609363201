import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import PageLayout from 'common/components/PageLayout/PageLayout';
import PageContainer from 'common/layout/PageContainer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';
import Dropdown from 'modules/artist/pages/components/Dropdown/Dropdown';

import RegionSection from './components/RegionSection/RegionSection';
import restService from 'services/rest.service';
import { useQuery } from '@apollo/client';
import { GET_ALL_DROPS } from 'modules/artist/graphql/Queries.graphql';
import jwtDecode from 'jwt-decode';
import { useStoreState } from 'store/store';
import { ILocationsData } from 'services/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { footerHeight, footerMobileHeight } from 'utils/constants';
import { useViewport } from 'use-viewport';
import CountryListPlaceholder from 'common/components/Placeholder/CountryList';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';

const quickLinks = [
  {
    title: 'Artist Profile',
    link: '/artist/settings',
  },
  {
    title: 'Manage Releases',
    link: '/artist/releases',
  },
  {
    title: 'Insights & Analytics',
    link: '/artist/insights',
  },
  {
    title: 'View Users',
    link: '/artist/insights/users',
  },
  /*{
    title: 'Accounting Management',
    link: '/artist/accounting/management',
  },
  {
    title: 'Payouts',
    link: '/artist/accounting/payouts',
  },*/
  {
    title: 'Artist Support (A&R)',
    link: '/support',
  },
  {
    title: 'Artist Dashboard',
    link: '/artist/dashboard',
  },
  {
    title: 'Marketing Tools',
    link: '/artist/marketing-tools',
  },
];

const CountryList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const viewport = useViewport();
  const accessToken = useStoreState(
    (state) => state.authentication.auth.accessToken,
  );

  const {
    data: dropsData = {
      allSongs: {
        nodes: [],
      },
    },
    loading,
  } = useQuery(GET_ALL_DROPS, {
    variables: {
      userId: jwtDecode<{ user_id: string }>(accessToken ?? '').user_id,
    },
  });

  const DropsList_dropdown = useMemo(
    () => [
      {
        label: 'All Releases',
        value: 'all',
      },
      ...dropsData.allSongs.nodes.map((dropData: any) => ({
        label: dropData.title,
        value: dropData.id,
      })),
    ],
    [dropsData],
  );

  const [countriesData, setCountriesData] = useState<ILocationsData[]>([]);
  const [currentDrop, setCurrentDrop] = useState<{
    value: string | number;
    label: string;
  }>(DropsList_dropdown[0]);

  useEffect(() => {
    const queryParams = location.state as { [x: string]: string };

    if (queryParams?.release) {
      const targetDrop = DropsList_dropdown.find(
        (item) => item.value === queryParams.release,
      );
      if (targetDrop) setCurrentDrop(targetDrop);
    } else {
      restService.getInsightCountries().then((data) => setCountriesData(data));
    }
  }, [DropsList_dropdown]);

  useEffect(() => {
    if (currentDrop.value !== 'all') {
      const requestData: { [x: string]: string } = {
        song_id: currentDrop.value as string,
      };

      restService
        .getInsightCountries(requestData)
        .then((data) => setCountriesData(data));
    } else {
      restService.getInsightCountries().then((data) => setCountriesData(data));
      restService.getInsightCountries().then((data) => setCountriesData(data));
    }
  }, [currentDrop, location]);

  const countriesByContinent = useMemo(() => {
    return countriesData.reduce(
      (acc: { [continent: string]: ILocationsData[] }, item) => {
        if (!acc[item.continent_name]) {
          acc[item.continent_name] = [];
        }

        acc[item.continent_name].push(item);

        return acc;
      },
      {},
    );
  }, [countriesData]);

  const handleCountryClick = (location: string) => {
    const queryParam: { [x: string]: any } = {
      country: location,
    };

    if (currentDrop && currentDrop?.value !== 'all') {
      queryParam.release = currentDrop.value;
    }
    navigate(`/artist/insights/users`, {
      state: queryParam,
    });
  };

  const firstColumn = (
    <>
      <RowContainer>
        <Dropdown
          data={DropsList_dropdown}
          currentItem={currentDrop}
          onSelect={(item) => setCurrentDrop(item)}
        />
      </RowContainer>

      <Spacer height={40} />

      <Typography text="all countries" fontSize="fz24" fontWeight="bold" />

      {Object.keys(countriesByContinent).map((continent, index) => (
        <RegionSection
          key={`${continent} ${index}`}
          name={continent}
          countriesData={countriesByContinent[continent]}
          contryOnClick={handleCountryClick}
        />
      ))}
    </>
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}
      pageTitle={'Countries | SongBits'}>
      <PageLayout
        title="insights & analytics"
        is2ColumnLayout
        sections={[
          {
            content: !loading ? firstColumn : <CountryListPlaceholder />,
          },
          {
            content:
              viewport.width > 576 && !loading ? (
                <QuickLinks links={quickLinks} />
              ) : (
                <div style={{ marginTop: '-22px' }}>
                  <QuickLinksPlaceholder noLinks={5} />
                </div>
              ),
          },
        ]}
      />
    </PageContainer>
  );
};

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default CountryList;
