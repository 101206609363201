import React, { useState, FC, useEffect } from 'react';
import styled from 'styled-components';

import AccountSettingsSSOModal from './AccountSettingsSSOModal';
import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';
import theme from 'theme/theme';

import SBModal from 'common/components/Modal/SBModal';

interface ComponentProps {
  ssoType: "google" | "apple" | "facebook" | null;
  isSSOReturn: boolean;
  isSSOError?: string;
  isSSOEnrolment: boolean;
  isSSOComplete: boolean;
  onUpdate: () => void;
  refreshAccountSettings: () => void;
}

const AccountSettingsSSO: FC<ComponentProps> = ({
  ssoType,
  isSSOReturn,
  isSSOEnrolment,
  isSSOComplete,
  isSSOError,
  onUpdate
}) => {
  const [showSSOEnableModal, setShowSSOEnableModal] = useState<boolean>(false);
  
  useEffect(() => {

    if (isSSOReturn || isSSOEnrolment || isSSOComplete) {
      setShowSSOEnableModal(true);
    } else {
      setShowSSOEnableModal(false);
    }
  }, [isSSOReturn, isSSOEnrolment, isSSOComplete]);

  return (
    <Container>
      <Spacer height={20} />
      <EmailButtonContainer>
        {!ssoType ? (
          <>
            <Typography
              text={'Not enabled'}
              fontSize="fz18"
              fontColor={theme.colors.white}
            />
            <EnableButton
              className={'enable-mfa-button'}
              disabled={ssoType !== null}
              label={'enable'}
              onClick={() => setShowSSOEnableModal(true)}
            />
          </>
        ) : (
          <FormColumn>
            <FormRow>
              <Typography
                text={'Enabled'}
                fontSize="fz18"
                fontColor={theme.colors.white}
              />
              <EnableButton
                className={'remove-mfa-button'}
                disabled={!ssoType}
                label={ssoType === "apple" ? "Apple" : ssoType === "google" ? "Google" : ssoType === "facebook" ? "Facebook" : ""}
                onClick={() => {}}
              />
            </FormRow>
          </FormColumn>
        )}
      </EmailButtonContainer>

      <SBModal
        className={'setup-sso'}
        isOpen={showSSOEnableModal}
        width={'456px'}
        height='609px'
        mobileWidth={'100%'}
        mobileHeight={'100%'}
        top="10%"
        mobileTop="2%"
        content={
          <AccountSettingsSSOModal
            isSSOReturn={isSSOReturn}
            ssoType={ssoType ? ssoType : ""}
            isSSOComplete={isSSOComplete}
            isSSOError={isSSOError}
            onSuccess={onUpdate}
            onHide={() => {
              setShowSSOEnableModal(false);
            }}
          />
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
`;

const EmailButtonContainer = styled.div`
  width: 100%;
  padding: 0 20px 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EnableButton = styled(Button)`
  font-family: 'HKGrotesk-Regular';
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.yellow};
`;

const FormColumn = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const FormRow = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default AccountSettingsSSO;
