import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useStoreState } from 'store/store';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useViewport } from 'use-viewport';
import styled from 'styled-components';
import jwtDecode from 'jwt-decode';
import DatePicker from 'react-datepicker';

import Table, { TableDataType } from 'common/components/Table/Table';
import PageLayout from 'common/components/PageLayout/PageLayout';
import PageContainer from 'common/layout/PageContainer';

import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import Typography from 'common/components/Typography/Typography';
import PlusCircleIcon from 'common/icons/PlusCircle.icon';
import theme from 'theme/theme';
import Dropdown from 'modules/artist/pages/components/Dropdown/Dropdown';
import FilterIcon from 'common/icons/Filter.icon';
import Spacer from 'common/components/Spacer/Spacer';
import AlignmentIcon from 'common/icons/Alignment.icon';
import SelectedUsers from './components/SelectedUsers/SelectedUsers';
import UserDetailsPlaceholder from 'common/components/Placeholder/UserDetails';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import DropdownWithAction from './components/DropdownWithAction/DropdownWithAction';
import Button from 'common/components/Button/Button';

import { Input } from 'common/components/TextInput/TextInput.styled';

import restService from 'services/rest.service';

import MinusCircleIcon from 'common/icons/MinusCircle.icon';
import XIcon from 'common/icons/X.icon';
import ProfileIcon from 'common/icons/Profile.icon';

import 'common/styles/stylesheets/sb-datepicker.css';
import { OrderDirection } from 'typings/types';
import { GET_ALL_DROPS } from 'modules/artist/graphql/Queries.graphql';
import {
  AccountingUser,
  AccountingUsersOrderBy,
  Request_AccountingUsers,
} from 'services/types';
import { footerHeight, footerMobileHeight, xs } from 'utils/constants';
import {
  FiltersList_Dropdown,
  LimitsList,
  OrderDirectionsList_dropdown,
  OrdersList_dropdown,
} from './constants/filters';

type DropdownItem = {
  value: string | number;
  label: string;
};

const quickLinks = [
  {
    title: 'Artist Profile',
    link: '/artist/settings',
  },
  {
    title: 'Manage Releases',
    link: '/artist/releases',
  },
  {
    title: 'Insights & Analytics',
    link: '/artist/insights',
  },
  /*{
    title: 'Accounting Management',
    link: '/artist/accounting/management',
  },
  {
    title: 'Payouts',
    link: '/artist/accounting/payouts',
  },*/
  {
    title: 'Artist Support (A&R)',
    link: '/support',
  },
  {
    title: 'Artist Dashboard',
    link: '/artist/dashboard',
  },
  {
    title: 'Marketing Tools',
    link: '/artist/marketing-tools',
  },
];

const tableColumnsLong = [
  'User',
  'Location',
  'Registration Date',
  'Last Activity',
  'Total Purchase',
];

const tableColumnsShort = ['User', 'Location'];

const tableColumnsMobile = ['User', ''];

const UserDetail = () => {
  const location = useLocation();
  const viewport = useViewport();

  const accessToken = useStoreState(
    (state) => state.authentication.auth.accessToken,
  );

  const {
    data: dropsData = {
      allSongs: {
        nodes: [],
      },
    },
    loading,
  } = useQuery(GET_ALL_DROPS, {
    variables: {
      userId: jwtDecode<{ user_id: string }>(accessToken ?? '').user_id,
    },
  });

  const DropsList_dropdown = useMemo(
    () => [
      {
        label: 'All Releases',
        value: 'all',
      },
      ...dropsData.allSongs.nodes.map((dropData: any) => ({
        label: dropData.title,
        value: dropData.id,
      })),
    ],
    [dropsData],
  );

  const [initialFilterSet, setInitialFilterSet] = useState<boolean>(false); // Avoid double render during setCurrentFilter and setCurrentCountry

  const [usersData, setUsersData] = useState<AccountingUser[]>([]);
  const [usersDataLoading, setUsersDataLoading] = useState<boolean>(false);
  const [tableLeftOffset, setTableLeftOffset] = useState<string | undefined>();
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [isOrderActive, setIsOrderActive] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<DropdownItem>(
    OrdersList_dropdown[0],
  );
  const [selectedRows, setSelectedRows] = useState<TableDataType>([]);

  const [currentOrderDirection, setCurrentOrderDirection] =
    useState<DropdownItem>(OrderDirectionsList_dropdown[0]);
  const [currentCountry, setCurrentCountry] = useState<DropdownItem>({
    label: 'Countries',
    value: 'countries',
  });

  const [currentFilter, setCurrentFilter] = useState<DropdownItem>({
    label: 'Filter',
    value: 'filter',
  });

  const [currentLimit, setCurrentLimit] = useState<DropdownItem>(LimitsList[0]);
  const [currentDrop, setCurrentDrop] = useState<DropdownItem>(
    DropsList_dropdown[0],
  );
  const [countriesList, setCountriesList] = useState<DropdownItem[]>([
    {
      label: 'Countries',
      value: 'countries',
    },
  ]);

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const tableRef = useRef<HTMLDivElement>(null);

  const initialValues = useMemo(
    () => ({
      totalPurchaseAmount: {
        min: 0,
        max: 0,
      },
    }),
    [],
  );

  const { values, handleChange } = useFormik({
    initialValues,
    onSubmit: () => {},
  });

  async function resetData() {
    setUsersDataLoading(true);
    const data = await restService.getArtistAccountingUsers({});

    // setUsersData(data);
    // setUsersDataLoading(false);

    const tempCountries = data.map((item) => ({
      label: item.country_name,
      value: item.country_id,
    }));

    const countries: DropdownItem[] = [
      {
        label: 'Countries',
        value: 'countries',
      },
    ];

    for (const country of tempCountries) {
      const countryIndex = countries.findIndex(
        (item: any) => item.value === country.value,
      );

      if (countryIndex === -1) {
        countries.push(country);
      }
    }

    setCountriesList(countries);

    return { data, countries };
  }

  useEffect(() => {
    const queryParams = location.state as { [x: string]: any };

    resetData().then(({ data, countries }) => {
      if (queryParams) setIsFilterActive(true);

      if (queryParams?.country) {
        const targetCountry = countries.find(
          (item) => item.label === queryParams.country,
        );

        if (targetCountry) {
          setCurrentFilter({
            label: 'Country',
            value: 'country',
          });

          setCurrentCountry(targetCountry);
        }
      } else {
        setUsersData(data);
        setUsersDataLoading(false);
      }
      setInitialFilterSet(true);
    });
  }, []);

  useEffect(() => {
    const queryParams = location.state as { [x: string]: any };

    if (queryParams?.release) {
      const targetDrop = DropsList_dropdown.find(
        (item) => item.value === queryParams.release,
      );
      if (targetDrop) setCurrentDrop(targetDrop);
    }
  }, [DropsList_dropdown]);

  useEffect(() => {
    
    if (!initialFilterSet) return;

    const requestData: Request_AccountingUsers = {};

    if (currentDrop.value !== 'all') {
      requestData.song_id = currentDrop.value.toString();
    }

    if (isOrderActive) {
      requestData.order_by =
        currentOrder.value.toString() as AccountingUsersOrderBy;
      requestData.order_direction =
        currentOrderDirection.value.toString() as OrderDirection;
    }

    if (currentLimit.value !== 'limit') {
      if (currentLimit.value !== 'all') {
        requestData.limit = currentLimit.value as 10 | 50 | 100 | 'all';
      }
    }

    if (isFilterActive) {
      if (currentCountry.value !== 'countries') {
        requestData.country = currentCountry.value as number;
      }

      if (
        currentFilter.value === 'total_purchase_amount' &&
        values.totalPurchaseAmount.min &&
        values.totalPurchaseAmount.max
      ) {
        requestData.purchase_amount = {
          minimum: values.totalPurchaseAmount.min,
          maximum: values.totalPurchaseAmount.max,
        };
      }

      if (currentFilter.value === 'last_activity' && startDate && endDate) {
        requestData.last_activity_date = {
          minimum: startDate.toISOString().split('T')[0],
          maximum: endDate.toISOString().split('T')[0],
        };
      }
    }

    if (
      Object.keys(requestData).length !== 0 &&
      currentFilter.value !== 'filter'
    ) {
      setUsersDataLoading(true);
      restService.getArtistAccountingUsers(requestData).then((data) => {
        setUsersData(data);
        setUsersDataLoading(false);
      });
    } else if (Object.keys(requestData).length === 0) {
      resetData().then(({ data }) => {
        setUsersData(data);
        setUsersDataLoading(false);
      });
    }
  }, [
    initialFilterSet,
    isOrderActive,
    isFilterActive,
    currentOrder,
    currentDrop,
    currentOrderDirection,
    currentLimit,
    currentCountry,
    currentFilter,
    startDate,
    endDate,
    currentDrop
  ]);

  useEffect(() => {
    if (tableRef.current) {
      setTableLeftOffset(tableRef.current.offsetLeft + 'px');
    }
  }, [tableRef]);

  function onFilterSubmit() {
    const requestData: Request_AccountingUsers = {};

    if (currentDrop.value !== 'all') {
      requestData.song_id = currentDrop.value.toString();
    }

    if (isOrderActive) {
      requestData.order_by =
        currentOrder.value.toString() as AccountingUsersOrderBy;
      requestData.order_direction =
        currentOrderDirection.value.toString() as OrderDirection;
    }

    if (currentLimit.value !== 'limit') {
      if (currentLimit.value !== 'all') {
        requestData.limit = currentLimit.value as 10 | 50 | 100 | 'all';
      }
    }

    if (isFilterActive) {
      if (currentCountry.value !== 'countries') {
        requestData.country = currentCountry.value as number;
      }

      if (
        currentFilter.value === 'total_purchase_amount' &&
        values.totalPurchaseAmount.min &&
        values.totalPurchaseAmount.max
      ) {
        requestData.purchase_amount = {
          minimum: values.totalPurchaseAmount.min,
          maximum: values.totalPurchaseAmount.max,
        };
      }

      if (currentFilter.value === 'last_activity' && startDate && endDate) {
        requestData.last_activity_date = {
          minimum: startDate.toISOString().split('T')[0],
          maximum: endDate.toISOString().split('T')[0],
        };
      }
    }
    
    setUsersDataLoading(true);
    restService.getArtistAccountingUsers(requestData).then((data) => {
      setUsersData(data);
      setUsersDataLoading(false);
    });
  }

  function formatPurchase(bits: number) {
    return bits.toLocaleString('en-US', { maximumFractionDigits: 2 });
  }

  useEffect(() => {
    getUserIDs(selectedRows);
  }, [selectedRows]);

  function getUserIDs(tableRows: TableDataType) {
    const userIDs: string[] = [];
    tableRows.forEach((row) => {
      const user = usersData.find(
        (user) => user.user_id === row[row.length - 1],
      );
      if (user) {
        userIDs.push(user.user_id);
      }
    });
    return userIDs;
  }

  const formatUsernameAndIcon = (
    username: string,
    slug: string,
    avatar: string | null,
  ) => {
    return (
      <div
        style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}
        onClick={() => {
          window.open(`${window.location.origin}/${slug}`, '_blank');
        }}>
        {avatar ? (
          <img
            src={avatar}
            width={25}
            height={25}
            style={{ borderRadius: '50%' }}
          />
        ) : (
          <ProfileIcon width={25} />
        )}
        <span style={{ cursor: 'pointer' }}>{username}</span>
      </div>
    );
  };

  const content = (
    <Wrapper>
      <HeaderContainer>
        <FiltersList>
          <Dropdown
            data={DropsList_dropdown}
            currentItem={currentDrop}
            onSelect={(item) => {
              setCurrentDrop(item)
              onFilterSubmit()
            }}
          />
          {isOrderActive && (
            <>
              <DropdownWithAction
                data={OrdersList_dropdown}
                currentItem={currentOrder}
                onSelect={(item) => setCurrentOrder(item)}
                onClose={() => setIsOrderActive(false)}
              />

              <Dropdown
                data={OrderDirectionsList_dropdown}
                currentItem={currentOrderDirection}
                onSelect={(item) => setCurrentOrderDirection(item)}
              />
            </>
          )}
          {isFilterActive && (
            <>
              <DropdownWithAction
                data={FiltersList_Dropdown}
                currentItem={currentFilter}
                onSelect={(item) => {
                  setCurrentFilter(item);

                  if (item.value === 'country') {
                    setCurrentOrder({
                      label: 'Location',
                      value: 'location',
                    });
                  }
                  if (item.value === 'last_activity') {
                    setCurrentOrder({
                      label: 'Last Activity Date',
                      value: 'last_activity_date',
                    });
                  }
                  if (item.value === 'continent') {
                    setCurrentOrder({
                      label: 'Location',
                      value: 'location',
                    });
                  }
                  if (item.value === 'total_purchase_amount') {
                    setCurrentOrder({
                      label: 'Total Purchase Amount',
                      value: 'total_purchase_costs',
                    });
                  }
                }}
                onClose={() => {
                  setIsFilterActive(false);
                  setCurrentFilter({
                    label: 'Filter',
                    value: 'filter',
                  });
                }}
              />
              {currentFilter.label === 'Country' && (
                <FilterWithClose>
                  <Dropdown
                    data={countriesList}
                    currentItem={currentCountry}
                    onSelect={(item) => setCurrentCountry(item)}
                  />
                  {currentCountry.label !== 'Countries' && (
                    <CloseButton
                      onClick={() => {
                        setCurrentCountry(countriesList[0]);
                      }}>
                      <XIcon />
                    </CloseButton>
                  )}
                </FilterWithClose>
              )}
              {currentFilter.label === 'Total Purchase Amount' && (
                <MultiInputFilterWrapper>
                  <FilterInputWrapper>
                    <InputWrapper>
                      <Typography
                        text="$"
                        fontColor={theme.colors.white}
                        fontWeight="light"
                      />
                      <FilterInput
                        inputMode="numeric"
                        value={values.totalPurchaseAmount.min}
                        onChange={handleChange('totalPurchaseAmount.min')}
                      />
                    </InputWrapper>
                    <Typography
                      text={'to'}
                      fontSize="fz18"
                      fontWeight="light"
                      fontColor={theme.colors.white}
                    />
                    <InputWrapper>
                      <Typography
                        text="$"
                        fontColor={theme.colors.white}
                        fontWeight="light"
                      />
                      <FilterInput
                        inputMode="numeric"
                        value={values.totalPurchaseAmount.max}
                        onChange={handleChange('totalPurchaseAmount.max')}
                      />
                    </InputWrapper>
                  </FilterInputWrapper>
                  <SubmitButton
                    label={
                      <Typography
                        text="Apply"
                        fontSize="fz18"
                        fontWeight="regular500"
                      />
                    }
                    onClick={onFilterSubmit}
                  />
                </MultiInputFilterWrapper>
              )}
              {currentFilter.label === 'Last Activity' && (
                <MultiInputFilterWrapper>
                  <FilterInputWrapper>
                    <div>
                      <CustomDatePicker
                        selected={startDate}
                        placeholderText={'From'}
                        onChange={(date: Date) =>
                          setStartDate(date || startDate)
                        }
                      />
                    </div>
                    <Typography
                      text={'to'}
                      fontSize="fz18"
                      fontWeight="light"
                      fontColor={theme.colors.white}
                    />
                    <div>
                      <CustomDatePicker
                        selected={endDate}
                        placeholderText={'until'}
                        onChange={(date: Date) => setEndDate(date || endDate)}
                      />
                    </div>
                  </FilterInputWrapper>
                  <SubmitButton
                    label={
                      <Typography
                        text="Apply"
                        fontSize="fz18"
                        fontWeight="regular500"
                      />
                    }
                    onClick={onFilterSubmit}
                  />
                </MultiInputFilterWrapper>
              )}
            </>
          )}
        </FiltersList>
        <IconsContainer>
          <ButtonWrapper
            onClick={() => {
              setIsOrderActive((prev) => !prev);
              setIsFilterActive(false);
            }}>
            <AlignmentIcon
              fill={isOrderActive ? theme.colors.yellow : 'white'}
            />
          </ButtonWrapper>
          <ButtonWrapper
            onClick={() => {
              setIsFilterActive((prev) => !prev);
              setIsOrderActive(false);
            }}>
            <FilterIcon
              fill={isFilterActive ? theme.colors.yellow : 'white'}
              width={viewport.width > 576 ? 23 : 22}
              height={20}
            />
          </ButtonWrapper>
        </IconsContainer>
      </HeaderContainer>
      <Spacer height={20} />
      <Typography
        text="user detail"
        fontColor={theme.colors.yellow}
        fontWeight="bold"
        fontSize="fz24"
      />
      <Spacer height={20} />
      <HeaderContainer>
        <ShowFilterContainer>
          <Typography text={'Show'} fontWeight="light" />
          <ButtonWrapper
            onClick={() => {
              setCurrentLimit({ label: '100', value: 100 });
            }}>
            <Typography
              text={'100'}
              fontWeight="light"
              fontColor={
                currentLimit.value === 100
                  ? theme.colors.yellow
                  : theme.colors.white
              }
            />
          </ButtonWrapper>
          <Typography text={'|'} fontWeight="light" />
          <ButtonWrapper
            onClick={() => {
              setCurrentLimit({ label: '250', value: 250 });
            }}>
            <Typography
              text={'250'}
              fontWeight="light"
              fontColor={
                currentLimit.value === 250
                  ? theme.colors.yellow
                  : theme.colors.white
              }
            />
          </ButtonWrapper>
          <Typography text={'|'} fontWeight="light" />
          <ButtonWrapper
            onClick={() => {
              setCurrentLimit({ label: '500', value: 500 });
            }}>
            <Typography
              text={'500'}
              fontWeight="light"
              fontColor={
                currentLimit.value === 500
                  ? theme.colors.yellow
                  : theme.colors.white
              }
            />
          </ButtonWrapper>
          <Typography text={'|'} fontWeight="light" />
          <ButtonWrapper
            onClick={() => {
              setCurrentLimit({ label: 'all', value: 'all' });
            }}>
            <Typography
              text={'All'}
              fontWeight="light"
              fontColor={
                currentLimit.value === 'all'
                  ? theme.colors.yellow
                  : theme.colors.white
              }
            />
          </ButtonWrapper>
        </ShowFilterContainer>

        {viewport.width >= 576 && (
          <ExpandTableButton
            onClick={() => setIsTableExpanded((prev) => !prev)}>
            <Typography
              text={isTableExpanded ? 'Minimize Data' : 'Expand Data'}
              fontColor={theme.colors.white}
              fontWeight="light"
            />
            &nbsp;&nbsp;
            {isTableExpanded ? (
              <MinusCircleIcon
                width={15}
                height={15}
                fill={theme.colors.white}
              />
            ) : (
              <PlusCircleIcon
                width={15}
                height={15}
                fill={theme.colors.white}
              />
            )}
          </ExpandTableButton>
        )}
      </HeaderContainer>

      <div ref={tableRef}>
        <Table
          columns={
            isTableExpanded
              ? tableColumnsLong
              : viewport.width >= 576
              ? tableColumnsShort
              : tableColumnsMobile
          }
          data={usersData.map(
            ({
              username,
              country_name,
              registration_date,
              last_activity_date,
              total_purchase_costs,
              avatar,
              slug,
              user_id,
            }) => [
              formatUsernameAndIcon(username, slug, avatar),
              country_name,
              new Date(registration_date).toLocaleDateString('en-GB', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }),
              new Date(last_activity_date).toLocaleDateString('en-GB', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }),
              `$${formatPurchase(Number(total_purchase_costs)).toString()}`,
              user_id,
            ],
          )}
          style={{
            rowHeight: '40px',
          }}
          onRowsSelect={(rows) => setSelectedRows(rows)}
        />
      </div>

      {selectedRows.length > 0 && (
        <SelectedUsers
          selectedUsers={getUserIDs(selectedRows)}
          style={{ leftOffset: tableLeftOffset }}
        />
      )}
    </Wrapper>
  );

  const getPlaceholderSections = () => {
    const sections = [
      {
        content: <UserDetailsPlaceholder isTableExpanded={isTableExpanded} />,
      },
    ];

    if (!isTableExpanded && viewport.width > 576) {
      sections.push({
        content: (
          <div
            style={{
              marginTop: '-28px',
            }}>
            <QuickLinksPlaceholder noLinks={5} />
          </div>
        ),
      });
    }
    return sections;
  };

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}
      pageTitle={'View Users | SongBits'}>
      <PageLayout
        title="View Users"
        sections={
          loading || usersDataLoading
            ? getPlaceholderSections()
            : !isTableExpanded
            ? [
                {
                  content,
                },
                {
                  content: viewport.width > 576 && (
                    <div
                      style={{
                        marginBottom: viewport.width < 576 ? '120px' : '',
                      }}>
                      <QuickLinks links={quickLinks} />
                    </div>
                  ),
                },
              ]
            : [
                {
                  content,
                },
              ]
        }
        is2ColumnLayout={!isTableExpanded}
        width={isTableExpanded ? '80%' : 'initial'}
        // padding={'100px 50px 0 50px'}
        padding={
          viewport.width > 576 ? '100px 50px 0 50px' : '100px 20px 0 20px'
        }
      />
    </PageContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  position: relative;

  margin-bottom: 5px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;

const ButtonWrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;

  background: transparent;
  border: none;

  cursor: pointer;

  padding: 0 8px;

  @media (min-width: 768px) {
    padding: 1px 6px;
  }
`;

const ExpandTableButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;

  background: transparent;
  border: none;

  cursor: pointer;

  @media (min-width: 768px) {
    padding: 1px 6px;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: absolute;
  right: 0;

  padding-top: 12px;
  padding-bottom: 12px;

  @media (min-width: 768px) {
    gap: 8px;
  }
`;

const FiltersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
`;

const ShowFilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const MultiInputFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
`;

const FilterInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 309px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const FilterInput = styled(Input)`
  font-weight: 300;
  padding-left: 0;
`;

const InputWrapper = styled.div`
  display: flex;
  border: 1px solid ${theme.colors.yellow};
  border-radius: 10px;
  width: 100px;
  height: 46px;
  align-items: center;
  padding-left: 10px;

  @media (min-width: ${xs}px) {
    width: 120px;
  }
`;

const SubmitButton = styled(Button)`
  border: 2px solid ${theme.colors.yellow};
  border-radius: 10px;
  width: 120px;
  height: 46px;
`;

const CustomDatePicker = styled(DatePicker)`
  width: 120px;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;

  margin-left: -3px;

  cursor: pointer;

  @media (min-width: 768px) {
    margin-left: 5px;
  }
`;

const FilterWithClose = styled.div`
  display: flex;
  flex-direction: row;
`;

export default UserDetail;
