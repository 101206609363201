import * as React from 'react';

import { IconProps } from 'common/common.types';

const SuiVisionIcon = ({ width = 42, height = 53 }: IconProps) => (
  <svg 
    width={width}
    height={height}
    viewBox="0 0 42 53" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M33.0525 22.1044L33.0515 22.1066C35.2055 24.8178 36.4929 28.2517 36.4929 31.9866C36.4929 35.777 35.1671 39.2576 32.9549 41.9871L32.7645 42.2221L32.714 41.9235C32.6709 41.6694 32.6206 41.413 32.5619 41.1545C31.4548 36.2717 27.8478 32.0848 21.9111 28.6946C17.9022 26.4116 15.6074 23.6625 15.0049 20.5391C14.6158 18.5193 14.9051 16.4906 15.4642 14.753C16.023 13.016 16.8544 11.5606 17.5607 10.6843L19.8705 7.84915C20.2755 7.35204 21.0335 7.35204 21.4385 7.84915L33.0525 22.1044ZM36.7053 19.2722L21.2266 0.27207C20.9311 -0.0906899 20.3777 -0.09069 20.0823 0.27207L4.60301 19.2713L4.55307 19.3351C1.7046 22.8832 0 27.3923 0 32.3007C0 43.7324 9.2471 53 20.6544 53C32.0618 53 41.3088 43.7324 41.3088 32.3007C41.3088 27.3923 39.6042 22.8832 36.7559 19.3351L36.7053 19.2722ZM8.30672 22.0431L9.69187 20.3429L9.73371 20.6565C9.76685 20.9052 9.80705 21.155 9.85472 21.4058C10.7505 26.124 13.9507 30.058 19.3011 33.105C23.9519 35.7621 26.6597 38.8173 27.4399 42.1684C27.7654 43.5668 27.8235 44.9427 27.6825 46.1456L27.6738 46.2202L27.6067 46.253C25.5076 47.2824 23.148 47.8601 20.6539 47.8601C11.9066 47.8601 4.81507 40.7535 4.81507 31.9866C4.81507 28.2226 6.12236 24.764 8.30672 22.0431Z" fill="white"/>
  </svg>
);

export default SuiVisionIcon;
