import React, { useMemo, useEffect, useState } from 'react';
import { useViewport } from 'use-viewport';

import theme from 'theme/theme';
import PageContainer from 'common/layout/PageContainer';
import Typography from 'common/components/Typography/Typography';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import Spacer from 'common/components/Spacer/Spacer';
import TextInputPlaceholder from 'common/components/Placeholder/TextInput';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';

import PageLayout from 'common/components/PageLayout/PageLayout';

import PDFIcon from 'common/icons/PDFIcon.icon';
import ExcelIcon from 'common/icons/ExcelIcon.icon';
import SongBitsLogos from 'common/icons/SongBitsLogos.icon';

import {
  Row,
  Column,
  LinkButton,
  FileText
} from '../styled/MarketingTools.styled';


const MarketingTools = () => {
  
  const viewport = useViewport();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
        setLoading(false)
    }, 500);
  }, [])

  const downloadFile = (path: string, name: string) => {
    const pdfUrl = path;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = name; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  const quickLinks = useMemo(
    () => [
        {
          title: 'Artist Dashboard',
          link: '/artist/dashboard',
        },
        {
          title: 'Artist Profile',
          link: '/artist/settings',
        },
        {
          title: 'Manage Releases',
          link: '/artist/releases',
        },
        {
          title: 'Insights & Analytics',
          link: '/artist/insights',
        },
        {
          title: 'View Users',
          link: '/artist/insights/users',
        },
        {
          title: 'Artist Support (A&R)',
          link: '/support',
        },
    ],
    [],
  );

  const contentSection = (
    <>
      {!loading ? (
        <>
        <Row>
            <ExcelIcon></ExcelIcon>
          <Column>
            <FileText
              text={'Artist social media content schedule'}
              fontWeight="bold"
              fontSize="fz18"
              lineHeight="24px"
            />
            <LinkButton onClick={() => {
              downloadFile(
                  window.location.origin + "/files/artist/" + 'SongBits Artist Content Assistant.xlsx',
                  'SongBits Artist Content Assistant.xlsx'
              )
            }}>
                  <Typography text="Download file" 
                      fontWeight="light"
                      fontSize="fz16"
                      fontColor={theme.colors.yellow} />
              </LinkButton>

          </Column>
        </Row>

          <Row>
              <PDFIcon></PDFIcon>
            <Column>
              <FileText
                text={'Educational video talking points'}
                fontWeight="bold"
                fontSize="fz18"
                lineHeight="24px"
              />
              <LinkButton onClick={() => {
                downloadFile(
                    window.location.origin + "/files/artist/" + 'SongBits Educational Video Talking Points.pdf',
                    'SongBits Educational Video Talking Points.pdf'
                )
              }}>
                    <Typography text="Download file" 
                        fontWeight="light"
                        fontSize="fz16"
                        fontColor={theme.colors.yellow} />
                </LinkButton>

            </Column>
          </Row>

          <Row>
              <PDFIcon></PDFIcon>
            <Column>
              <FileText
                text={'Behind the scenes interview \nscripts and questions'}
                fontWeight="bold"
                fontSize="fz18"
                lineHeight="24px"
              />
              <LinkButton onClick={() => {
                downloadFile(
                    window.location.origin + "/files/artist/" + 'SongBits BTS Interview Scripts & Questions.pdf',
                    'SongBits BTS Interview Scripts & Questions.pdf'
                )
              }}>
                    <Typography text="Download file" 
                        fontWeight="light"
                        fontSize="fz16"
                        fontColor={theme.colors.yellow} />
                </LinkButton>

            </Column>
          </Row>

          <Row>
              <PDFIcon></PDFIcon>
            <Column>
              <FileText
                text={'Artist live performance \nmessaging guidelines'}
                fontWeight="bold"
                fontSize="fz18"
                lineHeight="24px"
              />
              <LinkButton onClick={() => {
                downloadFile(
                    window.location.origin + "/files/artist/" + 'SongBits Artist Live Performance Messaging.pdf',
                    'SongBits Artist Live Performance Messaging.pdf'
                )
              }}>
                    <Typography text="Download file" 
                        fontWeight="light"
                        fontSize="fz16"
                        fontColor={theme.colors.yellow} />
                </LinkButton>

            </Column>
          </Row>

          <Row>
              <PDFIcon></PDFIcon>
            <Column>
              <FileText
                text={'Artist release communication\nguidelines'}
                fontWeight="bold"
                fontSize="fz18"
                lineHeight="24px"
              />
              <LinkButton onClick={() => {
                downloadFile(
                    window.location.origin + "/files/artist/" + 'SongBits Artist Release Comms Guidelines.pdf',
                    'SongBits Artist Release Comms Guidelines.pdf'
                )
              }}>
                    <Typography text="Download file" 
                        fontWeight="light"
                        fontSize="fz16"
                        fontColor={theme.colors.yellow} />
                </LinkButton>

            </Column>
          </Row>

          <Row>
              <SongBitsLogos></SongBitsLogos>
            <Column>
              <FileText
                text={'SongBits logo pack'}
                fontWeight="bold"
                fontSize="fz18"
                lineHeight="24px"
              />
              <LinkButton onClick={() => {
                downloadFile(
                    window.location.origin + "/files/artist/" + 'SongBits Logos.zip',
                    'SongBits Logos.zip'
                )
              }}>
                    <Typography text="Download file" 
                        fontWeight="light"
                        fontSize="fz16"
                        fontColor={theme.colors.yellow} />
                </LinkButton>

            </Column>
          </Row>
                    
        </>
      ) : (
        <>
          <TextInputPlaceholder></TextInputPlaceholder>
          <Spacer height={20} />
          <TextInputPlaceholder></TextInputPlaceholder>
          <Spacer height={20} />
          <TextInputPlaceholder></TextInputPlaceholder>
        </>
      )}
    </>
  );

  return (
    <PageContainer pageTitle='Marketing Tools | SongBits'>
      <PageLayout
        title="Marketing Tools"
        is2ColumnLayout
        loading={loading}
        sections={[
          {
            content: contentSection,
          },
          {
            content: (
              <div
                style={{
                  display: 'flex',
                  gap: '25px',
                  flexDirection: 'column',
                }}>
                {loading && viewport.width >= 767 ? (
                  <QuickLinksPlaceholder></QuickLinksPlaceholder>
                ) : (
                  <>
                    {viewport.width <= 767 ? (
                      <></>
                    ) : (
                      <QuickLinks links={quickLinks} isLogout />
                    )}
                  </>
                )}
              </div>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};
export default MarketingTools;
