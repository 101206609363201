import React, { useEffect } from 'react';
import styled from 'styled-components';
//import { AnimatePresence } from 'framer-motion';
import { useStoreState } from 'store/store';
import {
  Routes as Switch,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxToolkit';

import Layout from 'layout';

// Pre-Reg
import HomePage from 'modules/prereg/pages/HomePage';
import TellMeMore from 'modules/prereg/pages/TellMeMore';
import ShowMeWho from 'modules/prereg/pages/ShowMeWho';
import ShowMeWhy from 'modules/prereg/pages/ShowMeWhy';
import CommonQuestions from 'modules/prereg/pages/CommonQuestions';
import LabelsIntro from 'modules/prereg/pages/LabelsIntro';
import Company from 'modules/prereg/pages/Company';
import Support from 'modules/prereg/pages/Support';
import LegalRouter from 'modules/prereg/LegalRouter';
import Investors from 'modules/prereg/pages/Investors';
//import Careers from 'modules/prereg/pages/Careers';

// Auth
import CreateAccount from 'modules/auth/pages/CreateAccount';
import ConfirmEmail from 'modules/auth/pages/ConfirmEmail';
import ChangeEmail from 'modules/auth/pages/ChangeEmail';
import Login from 'modules/auth/pages/Login';
import LoginEmail from 'modules/auth/pages/LoginEmail';
import RemindPassword from 'modules/auth/pages/RemindPassword';
import ResetPassword from 'modules/auth/pages/ResetPassword';

// Waitlist
import JoinWaitlist from 'modules/auth/pages/JoinWaitlist';
import JoinWaitlistThanks from 'modules/auth/pages/JoinWaitlistThanks';

// Giveaway
import ClaimGiveaway from 'modules/prereg/pages/ClaimGiveaway';
import GiveawayWaitlist from 'modules/prereg/pages/GiveawayWaitlist';
import GiveawayWaitlistThanks from 'modules/prereg/pages/GiveawayWaitlistThanks';

// Account
import AccountSettings from 'modules/account/pages/AccountSettings';
import MyCollection from 'modules/account/pages/MyCollection';

// Library
import HotDropsPage from 'modules/library/pages/HotDropsPage';
import PublicSongProfile from 'modules/library/pages/SongProfilePublic';
import SongProfile from 'modules/library/pages/SongProfile';
import ArtistProfile from 'modules/library/pages/ArtistProfile';

// Misc
import PlaceholderScreen from 'modules/prereg/pages/PlaceholderScreen';
import AccountRouter from 'modules/account/AccountRouter';
import ArtistRouter from 'modules/artist/ArtistRouter';
import HomeRouter from 'modules/home/HomeRouter';
import ThreeDSecure from 'modules/account/pages/ThreeDSecure';
import { ProtectedRoute } from 'common/components/Routing/ProtectedRoute';
import ThreeDSecureFail from 'modules/account/pages/ThreeDSecureFail';
import ArtistApplication from 'modules/artist/pages/ArtistApplication';
import ArtistApplicationWaitlist from 'modules/prereg/pages/ArtistApplicationWaitlist';
import FreeBits from 'modules/prereg/pages/FreeBits';
import ArtistBenefits from 'modules/library/pages/ArtistBenefits';

const Routes = () => {
  const navigate = useNavigate();

  const { location: ModalLocation } = useAppSelector(
    (state) => state.modalSlice,
  );

  const isAuth = useStoreState((state) => state.authentication.isAuthenticated);
  const isArtist = useStoreState((state) => state.authentication.auth.tokenData?.is_artist || false);

  useEffect(() => {
    if (ModalLocation !== '') {
      navigate(ModalLocation);
    }
  }, []);

  return (
    <RouterContainer id="router-container">
      {/*<AnimatePresence>*/}
      <Switch location={location} key={location.pathname}>
        <Route path="/" element={<Layout></Layout>}>
          <Route path=":user" element={<ArtistProfile />} />
          <Route
            path="about-us"
            element={<PlaceholderScreen name="About Us" />}
          />

          <Route path="join-waitlist/thanks" element={<JoinWaitlistThanks />} />
          <Route path="join-waitlist" element={<JoinWaitlist />} />
          <Route path="change-email" element={<ChangeEmail />} />
          <Route path="reset-password/:token" element={<ResetPassword />} />
          <Route path="labels-artists" element={<LabelsIntro />} />
          <Route path="labels-artists/signup" element={<ArtistApplication />} />
          <Route
            path="artist/application"
            element={
              <ProtectedRoute>
                <ArtistApplication />
              </ProtectedRoute>
            }
          />
          <Route
            path="labels-artists/waitlist"
            element={<ArtistApplicationWaitlist />}
          />
          <Route path="FAQ" element={<CommonQuestions />} />
          <Route path="how" element={<TellMeMore />} />
          <Route path="who" element={<ShowMeWho />} />
          <Route path="why" element={<ShowMeWhy />} />
          <Route path="company" element={<Company />} />
          <Route path="investors" element={<Investors />} />
          {/*<Route path="careers" element={<Careers />} />*/}
          <Route path="investors" element={<Investors />} />
          <Route path="giveaway" element={<Navigate to={'/giveaway/sxsw'} />} />
          <Route path="giveaway/sxsw" element={<FreeBits />} />
          <Route path="giveaway/sxsw/waitlist" element={<GiveawayWaitlist />} />
          <Route
            path="giveaway/sxsw/thanks"
            element={<GiveawayWaitlistThanks />}
          />
          <Route
            path="giveaway/sxsw/claim"
            element={
              <ProtectedRoute>
                <ClaimGiveaway />
              </ProtectedRoute>
            }
          />
          <Route
            path="terms"
            element={<Navigate to={'/legal/terms-conditions'} />}
          />
          <Route
            path="terms-conditions"
            element={<Navigate to={'/legal/terms-conditions'} />}
          />
          <Route
            path="privacy"
            element={<Navigate to={'/legal/privacy-policy'} />}
          />
          <Route
            path="cookie"
            element={<Navigate to={'/legal/cookie-policy'} />}
          />
          {/*<Route path="community-guidelines" element={<Navigate to={'/legal/community-guidelines'} />} />*/}
          <Route path="legal/*" element={<LegalRouter />} />

          {/* Coming from create account */}
          <Route path="confirm-email" element={<ConfirmEmail />} />
          <Route path="confirm-email/:token" element={<ConfirmEmail />} />

          {isAuth ? (
            <>
              <Route
                path=""
                element={<Navigate to="/library/hot-releases" />}
              />
            </>
          ) : (
            <></>
          )}

          <Route
            path=":user/:song/bits"
            element={
                <SongProfile />
            }
          />

          <Route
            path=":user/:song"
            element={
                <PublicSongProfile />
            }
          />

          <Route
            path="home"
            element={
                <Navigate to="/library/hot-releases" />
            }
          />
          <Route
            path="library/collection/:id"
            element={
              <ProtectedRoute>
                <MyCollection />
              </ProtectedRoute>
            }
          />

          <Route path="library/">
            <Route
              path="hot-releases"
              element={
                  <HotDropsPage />
              }
            />
            {/*<Route path="listings" element={<LibraryListingsPage />} />*/}
            <Route
              path="collection"
              element={
                <ProtectedRoute>
                  <Navigate to="/library/my-collection" />
                </ProtectedRoute>
              }
            />
            <Route path="my-collection">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <MyCollection />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":user/:song/benefits"
                element={
                  <ProtectedRoute>
                    <ArtistBenefits />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path=""
              element={
                  <Navigate to="hot-releases" />
              }
            />
            <Route
              path="*"
              element={
                  <Navigate to="hot-releases" />
              }
            />
          </Route>
          <Route
            path="library"
            element={
                <Navigate to="/library/hot-releases" />
            }
          />
          <Route
            path="home/*"
            element={
              <ProtectedRoute>
                <HomeRouter />
              </ProtectedRoute>
            }
          />
          {isArtist ? (
            <Route
              path="artist/*"
              element={
                <ProtectedRoute>
                  <ArtistRouter />
                </ProtectedRoute>
              }
            />
          ) : (
            <></>
          )}
          <Route
            path="account/*"
            element={
              <ProtectedRoute>
                <AccountRouter />
              </ProtectedRoute>
            }
          />
          <Route
            path="confirm-email"
            element={
              <ProtectedRoute>
                <ConfirmEmail />
              </ProtectedRoute>
            }
          />
          <Route
            path="change-email"
            element={
              <ProtectedRoute>
                <ChangeEmail />
              </ProtectedRoute>
            }
          />
          <Route
            path="users/change-email/:token"
            element={
              <ProtectedRoute>
                <AccountSettings />
              </ProtectedRoute>
            }
          />
          <Route path="/3dsfail" element={<ThreeDSecureFail />} />
          <Route path="/3ds" element={<ThreeDSecure />} />
          <Route
            path="support"
            element={
              <ProtectedRoute>
                <Support />
              </ProtectedRoute>
            }
          />

          <Route path="" element={<HomePage />} />
          <Route path="create-account" element={<CreateAccount />} />

          

          {/* Coming from email reset email */}
          <Route
            path="users/change-email/:token"
            element={
              <ProtectedRoute>
                <AccountSettings />
              </ProtectedRoute>
            }
          />
          <Route path="change-email" element={<ChangeEmail />} />
          <Route path="login/email" element={<LoginEmail />} />
          <Route path="login" element={<Login />} />
          <Route path="remind-password" element={<RemindPassword />} />
          <Route path="/3dsfail" element={<ThreeDSecureFail />} />
          <Route path="/3ds" element={<ThreeDSecure />} />
          <Route path=":user/:song" element={<PublicSongProfile />} />

          <Route path="logout" element={<Navigate to={'/'} />} />
          <Route path=":user" element={<ArtistProfile />} />
          {/* <Route
              path="*"
              element={<Navigate to={isAuth ? '/library/hot-releases' : '/'} />}
            /> */}
        </Route>
      </Switch>
      {/*</AnimatePresence>*/}
    </RouterContainer>
  );
};

const RouterContainer = styled.div`
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  overscroll-behavior: auto;
  position: relative;
  background-color: ${(props) => props.theme.colors.black};
`;

export default Routes;
