import { action, actionOn, computed, thunk, thunkOn, Actions } from 'easy-peasy';

import { WebsocketModel, SongStats, WebsocketEventStatusUpdate } from './websocketModel.types';
import { StoreModel } from '../mainModel.types';

import queryString from 'query-string';

import websocketService from 'services/websocket.service'

const MAX_EVENTS_STORED = 50;
const MAX_STATUS_UPDATES_STORED = 75;

//const is_development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const getStoreListeners = (listener: "thunk" | "action", storeActions: Actions<StoreModel>) => {
  const listeners: Array<any> = [];
  Object.keys(storeActions).forEach((store_name) => {
    Object.keys((storeActions as any)[store_name]).forEach(action_name => {
      const handler = (storeActions as any)[store_name][action_name];
      const action_string = handler.prototype.constructor.def.meta.type;
      if (action_string.startsWith(`@${listener}.`)) {
        listeners.push(handler);
      }
    });
  });
  return listeners;
}

const mapStoreActions = (_:any, storeActions: Actions<StoreModel>) => {
  //if (!is_development) return [];
  return getStoreListeners("action", storeActions);
}
const mapStoreThunks = (_:any, storeActions: Actions<StoreModel>) => {
  //if (!is_development) return [];
  return getStoreListeners("thunk", storeActions);
}

export const websocket: WebsocketModel = {
  version: null,
  setVersion: action((state, payload) => {
    state.version = payload;
  }),
  identify: thunk(async () => {
    return await websocketService.identify();
  }),
  authenticate: thunk(async () => {
    return await websocketService.authenticate();
  }),
  updateAuth: thunk(async () => {
    await websocketService.getAuthToken();
  }),
  logout: thunk(async () => {
    return websocketService.logout();
  }),
  onAuthRegister: thunkOn(
    (_, storeActions) => storeActions.authentication.registerUser.successType,
    async (actions) => {
      return await actions.identify();
    }
  ),
  onAuthAuthentication: thunkOn(
    (_, storeActions) => [
      storeActions.authentication.confirmEmail.successType,
      storeActions.authentication.authenticate.successType,
      storeActions.authentication.authenticateSSO.successType,
    ],
    async (actions) => {
      return await actions.authenticate();
    }
  ),
  onAuthLogout: thunkOn(
    (_, storeActions) => storeActions.authentication.logout.successType,
    async (actions) => {
      return await actions.logout();
    }
  ),
  events: [],
  songStats: [],
  statusUpdates: [],
  commandResponses: {},
  getEvent: computed(
    (state) =>  (
        (eventName: string) => {
          const matchedEvents = state.events.filter(event => event.event.name === eventName);
          if (matchedEvents.length > 0) {
            return matchedEvents[0];
          }
          return null;
        }
      )
  ),
  latestSongStats: computed(
    (state) => 
      (songSlug?: string) => (
        (
          songSlug === undefined ? state.songStats : (
            state.songStats.filter((song: SongStats) => song.room === songSlug
          )[0]
        )
      )
    )
  ),
  latestStatusUpdate: computed(
    (state) => 
      (updateType: string, optionalComparisons?: Record<string, any>) => {
        if (state.statusUpdates.length === 0) return null;
        const matchedEvents = state.statusUpdates.filter((update: WebsocketEventStatusUpdate) => (
            Object.keys(update.event.data)[0] === updateType
          )
        );
        if (matchedEvents.length > 0) {
          let valid = true;
          const update = matchedEvents[0].event.data[updateType];
          if (optionalComparisons) {
            Object.keys(optionalComparisons).forEach(comparison_field => {
              if (update[comparison_field] !== optionalComparisons[comparison_field]) {
                valid = false;
              }
            })
          }
          return valid === true ? update : null;
        }
      }
  ),
  pushEvent: action((state, payload) => {
    state.events.unshift(payload);
    if (state.events.length > MAX_EVENTS_STORED) {
      state.events.length = Math.min(
        state.events.length, MAX_EVENTS_STORED
      );
    }
  }),
  pushSongStats: action((state, payload) => {
    payload.forEach(newStat => {
      const exists = state.songStats.filter(storedStat => storedStat.room === newStat.room).length === 1;
      if (exists) {
        state.songStats = state.songStats.map(storedStat => {
          return newStat.room === storedStat.room ? newStat : storedStat;
        })
      } else {
        state.songStats.push(newStat);
      }
    });
  }),
  pushStatusUpdate: action((state, payload) => {
    state.statusUpdates.unshift(payload);
    if (state.statusUpdates.length > MAX_STATUS_UPDATES_STORED) {
      state.statusUpdates.length = Math.min(
        state.statusUpdates.length, MAX_STATUS_UPDATES_STORED
      );
    }
  }),
  setVeriffCancelled: thunk(async (_, payload) => {
    payload.status = "cancelled";
    const response = await websocketService.command("veriff", payload);
    if (response.status === false) throw new Error("Cannot set Veriff as cancelled");
  }),
  fcaStep: null,
  pushFCAStatus: thunk((_, payload, { getState }) => {
    const state = getState();
    state.fcaStep = payload;
  }),
  kycStatus: null,
  pushKYCStatus: thunk((_, payload, { getState }) => {
    const state = getState();
    state.kycStatus = payload;
  }),
  triggerDevAction: actionOn(
    mapStoreActions,
    (_, target) => {
      const type_parts = target.type.split(".");
      const eventData = {type: type_parts[0], store: type_parts[1], action: type_parts[2], payload: target.payload}
      if (eventData.type !== "@action") return;
      const el = document.getElementById("root");
      if (el) {
        el.dispatchEvent(new CustomEvent("Songbits::Playwright::Debug::Action", {detail: JSON.stringify((eventData as any))}));
      }
    }
  ),
  triggerDevThunk: thunkOn(
    mapStoreThunks,
    (_, target) => {
      const type_parts = target.type.split(".");
      const matches = type_parts[2].match(/^([a-zA-Z0-9_]+)\(([^\)]+)\)$/);
      const thunk = matches ? matches[1] : type_parts[2];
      const status = matches ? matches[2] : null;
      const eventData = {type: type_parts[0], store: type_parts[1], thunk, status,
        payload: target.payload || null,
        result: target.result || null,
        error: target.error || null,
      }
      if (eventData.type !== "@thunk") return;
      const el = document.getElementById("root");
      if (el) {
        el.dispatchEvent(new CustomEvent("Songbits::Playwright::Debug::Thunk", {detail: JSON.stringify((eventData as any))}));
      }
    }
  ),
  isReloadBlocked: false,
  reloadAfterUnblock: false,
  setReloadAfterUnblock: thunk((_, payload, { getState }) => {
    const state = getState();
    state.reloadAfterUnblock = payload;
  }),
  blockReload: thunk((actions, payload, { getState }) => {
    const state = getState();
    state.isReloadBlocked = payload;
    if (!payload && state.reloadAfterUnblock === true) {
      actions.setReloadAfterUnblock(false);
      actions.doReload();
    } 
  }),
  triggerReload: thunk((actions, _, { getState }) => {
    const state = getState();
    if (state.isReloadBlocked) {
      actions.setReloadAfterUnblock(true);
    } else {
      actions.doReload();
    }
  }),
  doReload: thunk((actions, _, { getState }) => {
    const state = getState();
    setTimeout(() => {
      window.localStorage.setItem("songbits:version", state.version?.full_build_number || "");
      if (window.location.search.length > 0) {
        const parsed_query = queryString.parse(window.location.search);
        parsed_query.reload = String(Date.now());
        const new_query_string = queryString.stringify(parsed_query);
        window.location.href = window.location.href.split("?")[0] + "?" + new_query_string;
      } else {
        window.location.href = window.location.href + "?reload=" + String(Date.now());
      }
    }, 3000);
  })
}