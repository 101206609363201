import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'store/store';
import { useViewport } from 'use-viewport';
import { useIntercom } from 'react-use-intercom';

import LogoFooter from 'common/icons/LogoFooter.icon';
import Visa from 'common/icons/Footer/Visa.icon';
import ApplePay from 'common/icons/Footer/ApplePay.icon';
import Mastercard from 'common/icons/Footer/Mastercard.icon';
import GooglePay from 'common/icons/Footer/GooglePay.icon';

/*import Telegram from 'common/icons/Telegram.icon';
import Discord from 'common/icons/Discord.icon';
import TikTok from 'common/icons/TikTokFooter.icon';
import InstagramSmallWhite from 'common/icons/InstagramSmallWhite.icon';*/
import Intercom from 'common/icons/Intercom.icon';

import { md, sm, xs } from 'utils/constants';

import {
  Container,
  FooterText,
  IconButton,
  IconsContainer,
  IntercomContainer,
  LinkButton,
  LinkButtonsContainer,
  LogoButton,
  LogosContainer,
  CopyRightsContainer,
  CopyRightsLink 
} from './styled/Footer.style';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

const Footer = () => {
  const navigate = useNavigate();
  const navigateToHandler = (route: string) => {
    navigate(route);
  };

  const { show, boot } = useIntercom();

  const { isAuthenticated } = useStoreState((state) => state.authentication);

  const showFooterRoutes = [
    '/',
    '/sign-in',
    '/FAQ',
    '/how',
    '/who',
    '/why',
    '/labels-artists',
    '/tomgrennan',
    '/house-drop',
    '/company',
    '/support',
    '/marketplace',
    '/about-us',
    '/artists-area',
  ];

  const dontShowFooterRoutes = [
    '/remind-password',
    '/login',
    '/login/email',
    '/change-email',
    '/users/change-email/',
    '/confirm-email',
    '/create-account',
    '/confirm-email/',
    '/reset-password/',
  ];

  function showFooter() {
    if (isAuthenticated) {
      return false;
    }
    if (showFooterRoutes.includes(location.pathname)) {
      return true;
    } else {
      if (dontShowFooterRoutes.includes(location.pathname)) {
        return false;
      }
      if (isAuthenticated) {
        return false;
      }
      return true;
    }
  }

  function hideDetails() {
    return false;
  }

  function toggleIntercom() {
    boot();
    show();
  }

  const renderContent = useCallback(() => {
    const viewport = useViewport();

    const logoWidth = viewport.width < xs ? 40 : 50;
    const logoHeight = viewport.width < xs ? 27 : 44;
    const footerTextSize = viewport.width < xs ? 'fz7' : 'fz9';
    const footerLinkTextSize = viewport.width < xs ? '7' : '9';

    const intercomSize = viewport.width < md ? 40 : 52;

    return showFooter() ? (
      <Container showFooter={true}>
        <LogosContainer>
          <LogoButton
            label={<LogoFooter height={logoHeight} width={logoWidth} />}
            onClick={() => {
              navigateToHandler('/');
            }}
          />
          <LinkButtonsContainer>
            <LinkButton
              label={
                <FooterText>
                  {viewport.width < sm
                    ? 'labels + artists'
                    : 'labels + artists'}
                </FooterText>
              }
              onClick={() => {
                navigateToHandler('/labels-artists');
              }}
            />
            <LinkButton
              label={<FooterText>company</FooterText>}
              onClick={() => {
                navigateToHandler('/company');
              }}
            />
            <LinkButton
              label={<FooterText>investors</FooterText>}
              onClick={() => {
                navigateToHandler('/investors');
              }}
            />
          </LinkButtonsContainer>
        </LogosContainer>

        <IconsContainer>
        {!hideDetails() ? (
            <>
                <IconButton label={<Visa />} onClick={() => {}} />
            </>
          ) : (
            <></>
          )}
          {!hideDetails() ? (
            <>
                <IconButton label={<Mastercard />} onClick={() => {}} />
            </>
          ) : (
            <></>
          )}
          {!hideDetails() ? (
            <>
                <IconButton label={<ApplePay />} onClick={() => {}} />
            </>
          ) : (
            <></>
          )}
          {!hideDetails() ? (
            <>
                <IconButton label={<GooglePay />} onClick={() => {}} />
            </>
          ) : (
            <></>
          )}
        </IconsContainer>

        <IntercomContainer
          label={<Intercom width={intercomSize} height={intercomSize} />}
          onClick={() => {
            toggleIntercom();
          }}
        />

        <CopyRightsContainer>
          <Typography text={'© ' + new Date().getFullYear().toString() + ' SongBits Ltd. All Rights Reserved.'} fontSize={footerTextSize} fontColor={theme.colors.white} ></Typography>
            <Typography text={'|'} fontSize={footerTextSize} fontColor={theme.colors.white} ></Typography>
            <CopyRightsLink fontSize={footerLinkTextSize}  to="/legal">Terms & Conditions</CopyRightsLink>
            <Typography text={'|'} fontSize={footerTextSize} fontColor={theme.colors.white} ></Typography>
            <CopyRightsLink fontSize={footerLinkTextSize}  to="/legal/privacy-policy">Privacy Policy</CopyRightsLink>
        </CopyRightsContainer>
      </Container>
    ) : ( 
      <></>
    );
  }, []);

  return renderContent();
};

export default memo(Footer);
