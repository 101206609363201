import React, { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useStoreActions, useStoreState } from 'store/store';

import styled from 'styled-components';

import restService from 'services/rest.service';

import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import {
  BitPurchaseSchema,
  CardSchema,
  PaymentModalType,
  SuccessModalType,
} from 'modules/payments/types';

import AddCard from 'modules/payments/PaymentModals/AddCard';
import CardPayment from 'modules/payments/PaymentModals/CardPayment';
import CardAuto from 'modules/payments/PaymentModals/CardAuto';
import PurchaseSuccess from 'modules/payments/PaymentModals/PurchaseSuccess';
import CardPaymentFailed from 'modules/payments/PaymentModals/CardPaymentFailed';
import ConfirmPurchase from 'modules/payments/PaymentModals/ConfirmPurchase';
import CancelPurchase from 'modules/payments/PaymentModals/CancelPurchase';
import PurchaseExpired from 'modules/payments/PaymentModals/PurchaseExpired';
import QueueFull from 'modules/payments/PaymentModals/QueueFull';
import { useAppDispatch, useAppSelector } from 'hooks/reduxToolkit';

import { resetPersist } from 'store-persist/reducers/modalSlice';
import {
  setDataState,
  setModalType,
  setShowState,
  setExpiryTime,
} from 'store-persist/reducers/modalSlice';
import PurchaseTimer from './PurchaseTimer/PurchaseTimer';
import { CommissionType } from 'modules/library/types';

type BuyModalPaymentSelectionProps = {
  showModals: boolean;
  song: string;
  dai: string;
  artist: string;
  selectedBits: number;
  currentToastId?: string;
  costOfSelected: string;
  songPoster?: string;
  bitPrice: number;
  uri: string;
  nft: string;
  songId: string;
  commission: CommissionType;
  artistPlatform: "GB"|"US";
  onClose: () => void;
  refetchSongProfile: () => void;
};

const BuyModalPaymentSelection: React.FC<BuyModalPaymentSelectionProps> = ({
  showModals,
  song,
  dai,
  artist,
  selectedBits,
  costOfSelected,
  songPoster,
  bitPrice,
  uri,
  nft,
  songId,
  artistPlatform,
  onClose,
  refetchSongProfile,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { modalType, dataState, isReloaded, showState, expiryTime } =
    useAppSelector((state) => state.modalSlice);
  showModals;
  
  const setGlobalBanner = useStoreActions((actions) => actions.globalbanner.setGlobalBanner);
  const { setSSORedirect } = useStoreActions((state) => state.authentication);
  const { setDismissedSSOWarning } = useStoreActions((state) => state.authentication);
  const blockReload = useStoreActions((actions) => actions.websocket.blockReload)

  const suiEnabled = useStoreState((state) => state.authentication.auth.suiEnabled);

  const [isPurchasePossible, setIsPurchasePossible] = useState<boolean>(false);

  const [mostRecentCard, setMostRecentCard] = useState<CardSchema>({
    cardId: '',
    nickname: '',
    last4: '',
  });

  // Amount needed to add to funds when balance < cost of bits
  const [showQueueFullModal, setShowQueueFullModal] = useState<boolean>(false);
  const [purchaseLoading, setPurchaseLoading] = useState<boolean>(false);

  // MODALS
  const [showConfirmPurchaseModal, setShowConfirmPurchaseModal] =
    useState<boolean>(false);

  const [showPurchaseExpiredModal, setShowPurchaseExpiredModal] =
    useState<boolean>(false);

  const [showAddCardModal, setShowAddCardModal] = useState<{
    show: boolean;
    prevCard: string | undefined;
  }>({ show: false, prevCard: '' });

  const [showCardAutoModal, setShowCardAutoModal] = useState<boolean>(false);

  const [showCardPaymentModal, setShowCardPaymentModal] =
    useState<boolean>(false);

  const [showCardFailedModal, setShowCardFailedModal] =
    useState<boolean>(false);
  const [cardFailedText, setCardFailedText] = useState<string>('');

  const [showCancelPurchaseModal, setShowCancelPurchaseModal] =
    useState<boolean>(false);

  const [cost, setCost] = useState<string>(
    (selectedBits * bitPrice).toString(),
  );

  const [costWithFees, setCostWithFees] = useState<string>(
    (selectedBits * bitPrice).toString(),
  );
  costWithFees;

  type PurchaseSuccessType = {
    isOpen: boolean;
    type: SuccessModalType;
  };

  const [showPurchaseSuccessModal, setShowPurchaseSuccessModal] =
    useState<PurchaseSuccessType>({
      isOpen: false,
      type: SuccessModalType.Card,
    });

  useEffect(() => {

    if (isReloaded) {

      if (modalType === 'ConfirmPurchase') {
        setShowConfirmPurchaseModal(showState);
      }

      if (modalType === 'CardAuto') {
        setShowCardAutoModal(showState);
      }

      if (modalType === 'AddCard') {
        setShowAddCardModal({ show: true, prevCard: '' });
      }
    }
  }, []);

  const clearPurchaseStore = useStoreActions(
    (state) => state.purchase.resetPurchaseData,
  );

  useEffect(() => {
    if (showConfirmPurchaseModal) {
      dispatch(setModalType('ConfirmPurchase'));
      dispatch(setShowState(showConfirmPurchaseModal));
      
      dispatch(
        setDataState({
          song,
          dai,
          artist,
          selectedBits,
          costOfSelected,
          songPoster,
          bitPrice,
          uri,
          nft,
          songId,
          artistPlatform
        }),
      );
    }
  }, [showConfirmPurchaseModal]);

  useEffect(() => {
    if (showConfirmPurchaseModal) {
      setCost((bitPrice * selectedBits).toString());
      dispatch(setModalType('ConfirmPurchase'));
      dispatch(setShowState(showConfirmPurchaseModal));
      blockReload(true);
    }
  }, [showConfirmPurchaseModal]);

  useEffect(() => {
    initBalances();

    restService.getCardsList().then((res) => {
      const numCards = res.length;
      if (numCards > 0) {
        const card = res[0];
        const cardData = {
          cardId: card.cardid,
          nickname: card.nickname,
          last4: card.last4,
          verification_type: card.verification_type,
        };
        setMostRecentCard(cardData);
      }
    });

  }, []);

  useEffect(() => {
    if (expiryTime > 0 && expiryTime < new Date().getTime()) {
      purchaseHasExpired();
      blockReload(false);
    } else if (!isReloaded) {
      setShowConfirmPurchaseModal(showModals);
    }
  }, [showModals]);

  async function initBalances() {
    setIsPurchasePossible(false);
  }


  async function purchaseHasExpired() {
    closeAllModals();
    window.localStorage.removeItem('purchaseId');
    setShowPurchaseExpiredModal(true);
    //blockReload(false);
  }

  function deductionText() {
    const price_decimal = parseFloat(costOfSelected).toFixed(2);
    return `This purchase will deduct $${price_decimal} from your SongBits balance`;
  }

  async function initiatePurchase() {
    setPurchaseLoading(true);
    // Reset purchase attempts
    window.localStorage.removeItem('purchase_attempt');

    const purchaseDetails: BitPurchaseSchema = {
      // userWallet: 'user1_wallet1',
      bitUUID: nft,
      numberOfBits: selectedBits
    };

    restService
      .purchaseBit(purchaseDetails) // returns artist_platform
      .then((res) => {
        if (!isPurchasePossible) {
          window.localStorage.setItem('purchaseId', res.id);

          setCostWithFees(res.calculated_fees.total);
          setCost(res.calculated_fees.total);

          const data = {} as any;

          data.song = song;
          data.dai = dai;
          data.artist = artist;
          data.selectedBits = selectedBits;
          data.costOfSelected = res.calculated_fees.total;
          data.songPoster = songPoster;
          data.bitPrice = bitPrice;
          data.uri = uri;
          data.nft = nft;
          data.songId = songId;
          data.artistPlatform = artistPlatform;

          (window as any).gtag('event', 'purchase_confirm', {});

          (window as any).gtag('event', 'add_to_cart', {
            currency: 'USD',
            value: parseFloat(res.calculated_fees.total),
            shipping: parseFloat(res.calculated_fees.songbits_commission),
            items: [
              {
                item_id: songId,
                item_name: `${song}`,
                item_brand: `${artist}`,
                price: parseFloat(res.calculated_fees.total),
              },
            ],
          });

          dispatch(setDataState(data));

          const expiryTime = new Date();
          expiryTime.setMinutes(expiryTime.getMinutes() + 15);
          dispatch(setExpiryTime(expiryTime.getTime()));

          blockReload(true);

          //setShowAddFundsModal(true); // Payment Fork Crypto vs Card
          setShowCardAutoModal(true);
          setShowConfirmPurchaseModal(false);
          setPurchaseLoading(false);
        } else {
          blockReload(false);
          setPurchaseLoading(false);
          dispatch(setExpiryTime(0));
          setShowPurchaseSuccessModal({
            isOpen: true,
            type: SuccessModalType.Any,
          });
        }
      })
      .catch(function (error) {
        blockReload(false);
        if (
          error.message === 'Too many bits selected for purchase' ||
          error.message === 'Too many bits selected for reservation'
        ) {
          setPurchaseLoading(false);
          setShowConfirmPurchaseModal(false);
          setShowQueueFullModal(true);
          return;
        } else if (error.message === 'Song sold out') {
          setPurchaseLoading(false);
          setShowConfirmPurchaseModal(false);
          navigate(location.pathname.replace('/bits', ''));
          closeAllModals();
          refetchSongProfile();
          clearPurchaseStore();
          setGlobalBanner({
            title: 'Sorry',
            text: 'it is now Sold Out!..',
          });
        } else if (error.message === 'Not for sale yet') {
          setPurchaseLoading(false);
          closeAllModals();
          refetchSongProfile();
          clearPurchaseStore();
          navigate(location.pathname.replace('/bits', ''));
          setGlobalBanner({
            title: 'Sorry',
            text: 'it is not for sale yet!..',
          });
        } else {
          setPurchaseLoading(false);
          setGlobalBanner({
            title: 'Purchase Failed',
            text: error.message,
          });
          return;
        }
      });
  }

  function cancelPurchase() {
    if (window.localStorage.getItem('purchaseId')) {
      restService
        .cancelPurchase({
          purchaseId: window.localStorage.getItem('purchaseId') || '',
        })
        .then((res) => {
          res;
          window.localStorage.removeItem('purchaseId');
          window.localStorage.removeItem('purchase_attempt');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          blockReload(false);
          window.localStorage.removeItem('purchaseId');
          window.localStorage.removeItem('purchase_attempt');
        });
    }
  }

  function closeAllModals() {
    setShowConfirmPurchaseModal(false);
    setShowAddCardModal({ show: false, prevCard: '' });
    setShowCardAutoModal(false);
    setShowCardPaymentModal(false);
    setShowCardFailedModal(false);
    setShowPurchaseSuccessModal({ isOpen: false, type: SuccessModalType.Card });
    clearPurchaseStore();
    setShowCancelPurchaseModal(false);
    window.localStorage.removeItem('purchaseId');
    //blockReload(false);
    dispatch(resetPersist());
    onClose();
  }

  function closeAllModalsSoft() {
    setShowConfirmPurchaseModal(false);
    setShowAddCardModal({ show: false, prevCard: '' });
    setShowCardAutoModal(false);
    setShowCardPaymentModal(false);
    setShowCardFailedModal(false);
    setShowPurchaseSuccessModal({ isOpen: false, type: SuccessModalType.Card });
    setShowCancelPurchaseModal(false);
    //blockReload(false);
  }

  // GLOBAL FUNCTIONS
  useEffect(() => {
    const w = window as any;

    w.getCostOfSelected = function () {
      return cost;
    };

    w.onPurchaseSuccess = function (type: SuccessModalType) {
      // Pass type in params
      dispatch(setExpiryTime(0));
      setShowPurchaseSuccessModal({ isOpen: true, type: type });
    };

    w.switchToCardPayment = function () {
      //setShowCardPaymentModal(true);
      setShowCardAutoModal(true);
    };

    w.setGlobalBanner = function ({
      title,
      text,
    }: {
      title: string;
      text: string;
    }) {
      setGlobalBanner({ title: title, text: text });
    };
  }, []);

  const renderHead = useCallback(() => {
    if (isReloaded && dataState) {
      return (
        <>
          <HeadContainer>
            <AvatarContainer>
              <SongPosterContainer src={songPoster} />
            </AvatarContainer>
            <SongDetails>
              <TextContent
                text={dataState.artist}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.yellow}
              />
              <TextContent
                text={dataState.song}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.white}
              />
              {dataState.costOfSelected ? (
                <>
                  <TextContent
                    fontSize="fz24"
                    fontWeight="light"
                    text={
                      `` +
                      dataState.selectedBits.toLocaleString() +
                      ` bit${dataState.selectedBits === 1 ? '' : 's'}` +
                      ` | US$${Number(costOfSelected).toLocaleString()}`
                    }
                    fontColor={theme.colors.white}
                  />
                </>
              ) : (
                <>
                  <TextContent
                    fontSize="fz24"
                    fontWeight="light"
                    text={
                      `` +
                      dataState.selectedBits.toLocaleString() +
                      ` bit${dataState.selectedBits === 1 ? '' : 's'}` +
                      ` | US$${Number(
                        dataState.selectedBits * dataState.bitPrice,
                      ).toLocaleString()}`
                    }
                    fontColor={theme.colors.white}
                  />
                </>
              )}
            </SongDetails>
          </HeadContainer>
          {!showPurchaseSuccessModal.isOpen && !showCancelPurchaseModal && (
            <PurchaseTimer
              onZero={() => {
                if (!showPurchaseSuccessModal.isOpen) {
                  purchaseHasExpired();
                }
                refetchSongProfile();
              }}
            />
          )}
          {/* {getSelectionText()} */}
        </>
      );
    } else {
      return (
        <>
          <HeadContainer>
            <AvatarContainer>
              <SongPosterContainer src={songPoster} />
            </AvatarContainer>
            <SongDetails>
              <TextContent
                text={artist}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.yellow}
              />
              <TextContent
                text={song}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.white}
              />
              <TextContent
                fontSize="fz24"
                fontWeight="light"
                text={
                  `` +
                  selectedBits.toLocaleString() +
                  ` bit${selectedBits === 1 ? '' : 's'}` +
                  ` | US$${Number(cost).toLocaleString()}` // TODO : Float?
                }
                fontColor={theme.colors.white}
              />
            </SongDetails>
          </HeadContainer>
          {/* {getSelectionText()} */}
          {!showPurchaseSuccessModal.isOpen && !showCancelPurchaseModal && (
            <PurchaseTimer
              onZero={() => {
                if (!showPurchaseSuccessModal.isOpen) {
                  purchaseHasExpired();
                }
                refetchSongProfile();
              }}
            />
          )}
        </>
      );
    }
  }, [
    artist,
    song,
    selectedBits,
    bitPrice,
    songPoster,
    dataState,
    isReloaded,
    cost,
    showCancelPurchaseModal,
  ]);

  const renderPurchaseHead = useCallback(() => {
    if (isReloaded && dataState) {
      return (
        <>
          <HeadContainer>
            <AvatarContainer>
              <SongPosterContainer src={songPoster} />
            </AvatarContainer>
            <SongDetails>
              <TextContent
                text={dataState.artist}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.yellow}
              />
              <TextContent
                text={dataState.song}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.white}
              />
              {dataState.costOfSelected ? (
                <>
                  <TextContent
                    fontSize="fz24"
                    fontWeight="light"
                    text={
                      `` +
                      dataState.selectedBits.toLocaleString() +
                      ` bit${dataState.selectedBits === 1 ? '' : 's'}` +
                      ` | US$${Number(costOfSelected).toLocaleString()}`
                    }
                    fontColor={theme.colors.white}
                  />
                </>
              ) : (
                <>
                  <TextContent
                    fontSize="fz24"
                    fontWeight="light"
                    text={
                      `` +
                      dataState.selectedBits.toLocaleString() +
                      ` bit${dataState.selectedBits === 1 ? '' : 's'}` +
                      ` | US$${Number(
                        dataState.selectedBits * dataState.bitPrice,
                      ).toLocaleString()}`
                    }
                    fontColor={theme.colors.white}
                  />
                </>
              )}
            </SongDetails>
          </HeadContainer>
          {/* {getSelectionText()} */}
        </>
      );
    } else {
      return (
        <>
          <HeadContainer>
            <AvatarContainer>
              <SongPosterContainer src={songPoster} />
            </AvatarContainer>
            <SongDetails>
              <TextContent
                text={artist}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.yellow}
              />
              <TextContent
                text={song}
                fontSize="fz24"
                fontWeight="light"
                fontColor={theme.colors.white}
              />
              <TextContent
                fontSize="fz24"
                fontWeight="light"
                text={
                  `` +
                  selectedBits.toLocaleString() +
                  ` bit${selectedBits === 1 ? '' : 's'}` +
                  ` | US$${Number(cost).toLocaleString()}`
                }
                fontColor={theme.colors.white}
              />
            </SongDetails>
          </HeadContainer>
          {/* {getSelectionText()} */}
        </>
      );
    }
  }, [artist, song, selectedBits, bitPrice, songPoster, dataState, isReloaded]);

  // added for linting
  dai;
  uri;

  return (
    <>
      <ConfirmPurchase
        isOpen={showConfirmPurchaseModal}
        purchaseLoading={purchaseLoading}
        isPurchasePossible={isPurchasePossible}
        initiatePurchase={() => {
          if (suiEnabled) {
            initiatePurchase();
          } else {
            setSSORedirect(location.pathname as string)
            setDismissedSSOWarning(false)
            setShowConfirmPurchaseModal(false)
            //navigate("/account/settings", {state: {setup_sso: true}})
          }
        }}
        deductionText={deductionText()}
        header={renderHead()}
        onClose={onClose}
      />

      <CardAuto
        isOpen={showCardAutoModal}
        header={renderHead()}
        cost={cost}
        artistPlatform={artistPlatform}
        mostRecentCard={mostRecentCard}
        onClose={() => {
          setShowCancelPurchaseModal(true);
          //setShowCardAutoModal(false);
        }}
        onCardPayment={() => {
          setShowCardPaymentModal(true);
        }}
        onPaymentComplete={() => {
          setShowCardAutoModal(false);
          // Update balance
          initBalances();
          dispatch(setExpiryTime(0));
          setShowPurchaseSuccessModal({
            isOpen: true,
            type: SuccessModalType.Card,
          });

          window.localStorage.removeItem('purchase_attempt');

          const payment_type = 'card';

          (window as any).gtag('event', 'purchase', {
            transaction_id: window.localStorage.getItem('purchaseId'),
            value: cost,
            shipping: parseFloat(cost) * 10,
            currency: 'USD',
            payment_type: `${payment_type}`,
            items: [
              {
                item_id: `${songId}`,
                item_name: `${song}`,
                item_brand: `${artist}`,
              },
            ],
          });
        }}
        onPaymentFail={(text) => {
          setShowCardAutoModal(false);
          setCardFailedText(text);
          setShowCardFailedModal(true);
        }}
        onStartAgain={() => {
          cancelPurchase();
          refetchSongProfile();
          closeAllModals();
        }}
        onAddCard={(previousCard?: string) => {
          setShowCardAutoModal(false);
          setShowAddCardModal({ show: true, prevCard: previousCard });
        }}
        purchaseExpired={() => {
          purchaseHasExpired();
        }}
      />
    

      <AddCard
        isOpen={showAddCardModal.show}
        type={PaymentModalType.Quickflow}
        onClose={() => {
          setShowCancelPurchaseModal(true);
        }}
        changePaymentMethod={() => {
          setShowAddCardModal({ show: false, prevCard: '' });
          setShowCardAutoModal(true);
        }}
        onCardAdded={(card: CardSchema) => {
          setMostRecentCard(card);
          if (showAddCardModal.prevCard) {
            restService.deleteCard(showAddCardModal.prevCard).catch((error) => {
              error;
            });
          }
          setShowAddCardModal({ show: false, prevCard: '' });
          setShowCardAutoModal(true);
        }}
        isFromPayment={true}
      />

      <CardPayment
        isOpen={showCardPaymentModal}
        type={PaymentModalType.Quickflow}
        mostRecentCard={mostRecentCard}
        paymentAmount={costOfSelected}
        onClose={() => {
          setShowCardPaymentModal(false);
        }}
        onPaymentComplete={() => {
          dispatch(setExpiryTime(0));
          setShowCardPaymentModal(false);
          // Update balance
          initBalances();
          setShowPurchaseSuccessModal({
            isOpen: true,
            type: SuccessModalType.Card,
          });

          const payment_type = 'card';

          (window as any).gtag('event', 'purchase', {
            transaction_id: window.localStorage.getItem('purchaseId'),
            value: cost,
            shipping: parseFloat(cost) * 10,
            currency: 'USD',
            payment_type: `${payment_type}`,
            items: [
              {
                item_id: `${songId}`,
                item_name: `${song}`,
                item_brand: `${artist}`,
              },
            ],
          });
        }}
        onPaymentFail={() => {
          setShowCardPaymentModal(false);
          setShowCardFailedModal(true);
        }}
        onAddCard={() => {
          setShowCardPaymentModal(false);
          //setShowAddCardModal({ show: true, prevCard: '' });
        }}
      />

      <CardPaymentFailed
        isOpen={showCardFailedModal}
        header={renderHead()}
        failedText={cardFailedText}
        onRetryCard={() => {
          setShowCardFailedModal(false);
          setShowCardAutoModal(true);
        }}
        onClose={() => {
          setShowCancelPurchaseModal(true);
        }}
        onChangePaymentMethod={() => {
          closeAllModalsSoft();
          setShowCardAutoModal(true);
        }}
      />

      <CancelPurchase
        isOpen={showCancelPurchaseModal}
        header={renderHead()}
        onClose={() => {
          //setShowCancelPurchaseModal(false);
        }}
        onResume={() => {
          setShowCancelPurchaseModal(false);
        }}
        onChangePaymentMethod={() => {
          closeAllModalsSoft();
          setShowCardAutoModal(true);
        }}
        onCancel={() => {
          cancelPurchase();
          refetchSongProfile();
          closeAllModals();
        }}
      />

      <PurchaseSuccess
        isOpen={showPurchaseSuccessModal.isOpen}
        header={renderHead()}
        type={showPurchaseSuccessModal.type}
        onSuccess={() => {
          closeAllModals();
          refetchSongProfile();
          clearPurchaseStore();
        }}
        onClose={() => {
          refetchSongProfile();
          closeAllModals();
          setShowPurchaseSuccessModal({
            isOpen: false,
            type: SuccessModalType.Card,
          });
        }}
      />

      <PurchaseExpired
        isOpen={showPurchaseExpiredModal}
        header={renderPurchaseHead()}
        onClose={() => {
          refetchSongProfile();
          closeAllModals();
          window.localStorage.removeItem('purchase_attempt');
          window.localStorage.removeItem('purchaseId');
          setShowPurchaseExpiredModal(false);
        }}
      />

      <QueueFull
        isOpen={showQueueFullModal}
        artist={artist}
        song={song}
        image={songPoster}
        onRefresh={() => {
          refetchSongProfile();
          window.location.reload();
        }}
        onClose={() => {
          closeAllModals();
          refetchSongProfile();
          clearPurchaseStore();
          navigate('/');
        }}
      />
    </>
  );
};

const HeadContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

const AvatarContainer = styled.div`
  text-decoration: inherit;
`;

const SongPosterContainer = styled.img`
  height: 80px;
  width: 80px;
  object-fit: cover;
`;

const SongDetails = styled.div`
  width: 280px;
  height: 110px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: 'HKGrotesk-Black';
`;

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

export default BuyModalPaymentSelection;
