import store from 'store/store';
import { v4 as uuidv4 } from 'uuid';

import { WebsocketEvent, WebsocketEventStatusUpdate, FCAStep, VeriffKYCStep } from 'store/models/websocketModel/websocketModel.types';

const WEBSOCKET_RECONNECT = 1000;

import { sleep } from 'utils/functions';

let loaded_external = false;

const loadScriptFromUrl = (url: string) => {
  const head = document.querySelector("head");
  if (head) {
    const script = document.createElement("script");
    script.setAttribute("src", url);
    head.appendChild(script);
  }
}

interface WebsocketCommand {
  message_id: string;
  command: string;
  params: object;
}

interface WebsocketIncomingMessage {
  message_id: string;
  event?: {
    name: string;
    data: any;
  }
  response?: any;
}

class WebsocketClient {
  url: any;
  connecting: boolean;
  connected: boolean;
  connection: null | WebSocket;
  retry: null | ReturnType<typeof setTimeout>;
  auth: null | string;
  identified: null | string;
  responses: Record<string, Record<string, any> | Array<any>>;
  authSuccess: boolean;
  identifySuccess: boolean;
  dispatch: any;
  initialized: boolean;
  constructor() {
    //console.log("ws constructor")
    this.connecting = false;
    this.connected = false;
    this.connection = null;
    this.retry = null;
    this.auth = null;
    this.authSuccess = false;
    this.identifySuccess = false;
    this.identified = null;
    this.responses = {};
    this.initialized = false;
  }
  init = async (url: string) => {
    if (this.initialized) return;
    this.initialized = true;
    //console.log("ws init")
    this.url = url;
    const token = await this.getAuthToken();
    token
    //console.log("websocket token", token);
    this.connect();
  }
  getAuthToken = async () => {
    this.auth = await store.getActions().authentication.getAuthToken();
    return this.auth;
  }
  getIdentified = () => {
    //const isAuthenticated = store.getState().authentication.isAuthenticated;
    //const userId = store.getState().authentication.auth.tokenData.user_id;
    //this.identified = ((isAuthenticated === false && userId !== null) ? userId : null);
    this.identified = store.getState().authentication.register.results[0].auser.id;
    return this.identified;
  }
  connect = () => {
    if (this.connection === null && this.connecting === false) {
      //console.log("connecting")
      this.connecting = true;
      this.connection = new WebSocket(this.url);
      this.setEventHandlers();
    }
  }
  retryConnection = () => {
    if (this.connection === null && this.retry === null) {
      this.retry = setTimeout(() => {
        this.retry = null;
        this.connect();
      }, WEBSOCKET_RECONNECT);
    }
  }
  setEventHandlers = () => {
    if (this.connection) {
      this.connection.onopen = () => {
        //this.triggerExternalScripts();
        this.connecting = false;
        this.connected = true;
        setTimeout(() => {
          this.identify();
          this.authenticate();
        }, 1000);
      }
      this.connection.onmessage = (event) => {
        try {
          const parsed = JSON.parse(event.data);
          this.processIncomingMessage(parsed);
        } catch(e) {}
      }
      this.connection.onclose = () => {
        this.connected = false;
        this.connection = null;
        this.authSuccess = false;
        this.identifySuccess = false;
        this.retryConnection();
      }
      this.connection.onerror = (e) => {
        e
        //console.log("WS ERROR", this, e)
        try {
          this.connection?.close();
        } catch(e) {}
        this.connecting = false;
        this.connected = false;
        this.connection = null;
        this.retryConnection();
      }
    }
  }
  eventToStore = (data: WebsocketEvent) => {
    //this.dispatch({ name: "pushEvent", data })
    store.getActions().websocket.pushEvent(data);
  }
  statusToStore = (data: WebsocketEventStatusUpdate) => {
    store.getActions().websocket.pushStatusUpdate(data);
  }
  kycToStore = (fca_step: FCAStep, kyc_status: VeriffKYCStep) => {
    store.getActions().websocket.pushFCAStatus(fca_step);
    store.getActions().websocket.pushKYCStatus(kyc_status);
  }
  processIncomingMessage = (data: WebsocketIncomingMessage) => {
    //console.log("incoming", data);
    if (data.event) {
      switch (data.event.name) {
        case "ping":
          this.pong(data.message_id); break;
        case "status_update":
          const data_key = Object.keys(data.event.data)[0];
          switch (data_key) {
            case "veriff_kyc":
            case "veriff_poa":
              this.kycToStore(data.event.data[data_key].fca_step, data.event.data[data_key].kyc_status); break;
            default:
              this.statusToStore(data as WebsocketEventStatusUpdate);
          }
          break;
        case "system_version":
          store.getActions().websocket.setVersion(data.event.data);
          break;
        case "song_stats":
          store.getActions().websocket.pushSongStats(data.event.data);
          store.getActions().authentication.setReady();
          break;
        default:
          this.eventToStore(data as WebsocketEvent);
      }
    } else if (data.response) {
      this.responses[data.message_id] = data.response;
    }
  }
  send = async (data: WebsocketCommand) => {
    while (this.connected === false) {
      await sleep(10);
    }
    this.connection?.send(JSON.stringify(data));
  }
  sleepUntilError = async (message_id: string, command: string, params: Record<string, any>, ms: number) => {
    const responses = this.responses;
    return new Promise((resolve, reject) => {
      this.send({message_id, command, params});
      const timer = setTimeout(() => {
        clearInterval(loop);
        reject("No message response!");
      }, ms);
      const loop = setInterval(() => {
        //console.log("interval run")
        if (message_id in responses) {
          //console.log("message found")
          clearTimeout(timer);
          clearInterval(loop);
          const response = responses[message_id];
          delete responses[message_id];
          resolve(response);
        }
      }, 500);
    });
  }
  command = async (
    command: string, 
    params: Record<string, unknown> = {}, 
    message_id?: string
  ): Promise<any> => {
    if (message_id) {
      this.send({message_id, command, params});
    } else {
      message_id = uuidv4();
      return await this.sleepUntilError(message_id, command, params, 10000).catch(e => {
        console.error("Websocket command error", e.message);
      });
    }
  }
  pong = async (message_id: string) => {
    const token = await this.getAuthToken();
    this.command(`pong`, { token }, message_id);
    /*const el = document.getElementById("root");
      if (el) {
        el.dispatchEvent(new CustomEvent("Songbits::Playwright::Debug", ({ detail: {pong: }} as any)));
      }*/
  }
  identify = async () => {
    if (this.identifySuccess === false) {
      const identified = this.getIdentified();
      if (identified) {
        const response = await this.command(`identify`, { user_id: identified });
        if (response === "Identification success") {
          this.identifySuccess = true;
          return true;
        } else {
          this.identified = null;
          throw new Error(response);
        }
      }
    }
  }
  authenticate = async () => {
    if (this.authSuccess === false) {
      const token = await this.getAuthToken();
      if (token) {
        const response = await this.command(`authenticate`, { token });
        if (response === "Authentication success") {
          this.identifySuccess = false;
          this.authSuccess = true;
          this.identified = null;
        } else {
          this.authSuccess = false;
          this.auth = null;
          console.error("AUTH FAIL", response);
        }
      }
    }
  }
  logout = () => {
    if (this.identifySuccess === true || this.authSuccess === true) {
      this.command(`logout`, {status: true});
    }
    this.auth = null;
    this.authSuccess = false;
    this.identified = null;
    this.identifySuccess = false;
  }
  triggerExternalScripts = () => {
    if (!loaded_external) {
      loaded_external = true;
      loadScriptFromUrl("./files/hotjar.js");
    }
  }
}

const client = new WebsocketClient;

export default client;