import React, { useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import restService from 'services/rest.service';
import { useStoreState } from 'store/store';
import { getUniqueInsightLocations } from 'utils/artistAnalytics';

import PageLayout from 'common/components/PageLayout/PageLayout';
import PageContainer from 'common/layout/PageContainer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import UserLocations from '../components/UserLocations/UserLocations';
import Dropdown from 'modules/artist/pages/components/Dropdown/Dropdown';
import TopLocations from '../components/TopLocations/TopLocations';
import UsersByValue from '../components/UsersByValue/UsersByValue';
import UsersByActivity from '../components/UsersByActivity/UsersByActivity';

import { GET_ALL_DROPS } from 'modules/artist/graphql/Queries.graphql';
import { ILocationsData, IUserByActivity, IUserByValue } from 'services/types';
import { footerHeight, footerMobileHeight } from 'utils/constants';
import { useViewport } from 'use-viewport';
import ArtistAnalyticsPlaceholder from '../../../../common/components/Placeholder/ArtistAnalytics';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';

const quickLinks = [
  {
    title: 'Artist Profile',
    link: '/artist/settings',
  },
  {
    title: 'Manage Releases',
    link: '/artist/releases',
  },
  {
    title: 'View Users',
    link: '/artist/insights/users',
  },
  /*{
    title: 'Accounting Management',
    link: '/artist/accounting/management',
  },
  {
    title: 'Payouts',
    link: '/artist/accounting/payouts',
  },*/
  {
    title: 'Artist Support (A&R)',
    link: '/support',
  },
  {
    title: 'Marketing Tools',
    link: '/artist/marketing-tools',
  },
];

const ArtistAnalytics = () => {
  const navigate = useNavigate();
  const viewport = useViewport();
  const accessToken = useStoreState(
    (state) => state.authentication.auth.accessToken,
  );

  const {
    data: dropsData = {
      allSongs: {
        nodes: [],
      },
    },
    loading,
  } = useQuery(GET_ALL_DROPS, {
    variables: {
      userId: jwtDecode<{ user_id: string }>(accessToken ?? '').user_id,
    },
  });

  const DropsList_dropdown = useMemo(
    () => [
      {
        label: 'All Releases',
        value: 'all',
      },
      ...dropsData.allSongs.nodes.map((dropData: any) => ({
        label: dropData.title,
        value: dropData.id,
      })),
    ],
    [dropsData],
  );

  const [currentDrop, setCurrentDrop] = useState<{
    value: string | number;
    label: string;
  }>(DropsList_dropdown[0]);
  const [currentDropLocations, setCurrentDropLocations] = useState<
    ILocationsData[]
  >([]);
  const [currentUsersByValue, setCurrentUsersByValue] = useState<
    IUserByValue[]
  >([]);
  const [currentUsersByActivity, setCurrentUsersByActivity] = useState<
    IUserByActivity[]
  >([]);

  useEffect(() => {
    restService.getInsights().then((data) => {
      const tempLocations = data.locations;
      setCurrentDropLocations(getUniqueInsightLocations(tempLocations));
    });
  }, []);

  useEffect(() => {
    const dropData = dropsData.allSongs.nodes.find(
      (drop: any) => drop.id === currentDrop.value,
    );

    if (dropData) {
      restService.getInsightsSong(dropData.id).then((insightDrop) => {

        const tempLocations = insightDrop.locations;
        const uniqueLocations = getUniqueInsightLocations(tempLocations);
        setCurrentDropLocations(uniqueLocations);
        
        setCurrentUsersByValue(insightDrop.users_by_value);

        setCurrentUsersByActivity(insightDrop.users_by_activity);
      });
    } else if (currentDrop.value === 'all') {
      restService.getInsights().then((data) => {
        const tempLocations = data.locations;
        const uniqueLocations = getUniqueInsightLocations(tempLocations);
        setCurrentDropLocations(uniqueLocations);

        setCurrentUsersByValue(data.users_by_value);

        setCurrentUsersByActivity(data.users_by_activity);
      });
    }
  }, [currentDrop]);

  const handleAllCountriesClick = () => {
    const requstData: { [x: string]: string } = {};

    if (currentDrop.value !== 'all') {
      requstData.release = currentDrop.value as string;
    }

    navigate('/artist/insights/countries', {
      state: requstData,
    });
  };

  const content = (
    <>
      <Dropdown
        data={DropsList_dropdown}
        currentItem={currentDrop}
        onSelect={(item) => setCurrentDrop(item)}
      />

      <UserLocations
        locationsData={currentDropLocations}
        currentDrop={currentDrop}
      />

      <TopLocations
        locations={currentDropLocations}
        currentDrop={currentDrop}
        handleAllCountriesOnClick={handleAllCountriesClick}
      />

      <UsersByValue data={currentUsersByValue} />

      <UsersByActivity data={currentUsersByActivity} />
    </>
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}
      pageTitle={'Insights & Analytics | SongBits'}>
      <PageLayout
        title="Insights & Analytics"
        is2ColumnLayout
        sections={[
          {
            content: !loading ? content : <ArtistAnalyticsPlaceholder />,
          },
          {
            content: loading ? (
              <QuickLinksPlaceholder noLinks={5} />
            ) : viewport.width > 576 ? (
              <QuickLinks links={quickLinks} />
            ) : (
              <></>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};

export default ArtistAnalytics;
