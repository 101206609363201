import * as React from 'react';

import { IconProps } from '../common.types';

const SSOApple = ({
  width = 22,
  height = 24,
  fill = 'white',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7422 5.53846C12.8255 5.53846 14.1834 4.8061 14.992 3.82962C15.7244 2.94469 16.2584 1.70884 16.2584 0.472982C16.2584 0.30515 16.2431 0.137317 16.2126 0C15.0073 0.0457724 13.5578 0.808646 12.6881 1.8309C12.0016 2.60903 11.376 3.82963 11.376 5.08074C11.376 5.26383 11.4065 5.44692 11.4218 5.50795C11.4981 5.5232 11.6201 5.53846 11.7422 5.53846ZM7.9278 24C9.40778 24 10.0639 23.0083 11.91 23.0083C13.7867 23.0083 14.1986 23.9695 15.8464 23.9695C17.4637 23.9695 18.547 22.4743 19.5693 21.0095C20.7136 19.3312 21.1865 17.6834 21.2171 17.6071C21.1103 17.5766 18.013 16.3102 18.013 12.7552C18.013 9.67324 20.4542 8.28481 20.5915 8.178C18.9742 5.85887 16.5178 5.79784 15.8464 5.79784C14.0308 5.79784 12.5508 6.89638 11.6201 6.89638C10.6131 6.89638 9.28572 5.85887 7.7142 5.85887C4.72374 5.85887 1.6875 8.33058 1.6875 12.9994C1.6875 15.8983 2.81655 18.965 4.20498 20.9485C5.39506 22.6268 6.43257 24 7.9278 24Z" fill={fill}
    />
  </svg>
);

export default SSOApple;
