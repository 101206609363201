import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import restService from 'services/rest.service';
import { useViewport } from 'use-viewport';

import { BreadcrumbsContainer } from 'common/layout/styled/Breadcrumbs.styled';
import PageContainer from 'common/layout/PageContainer';
import MobileBreadcrumbs from 'common/layout/MobileBreadcrumbs';
import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';
import BenefitItem from './components/BenefitItem/BenefitItem';
import SBModal from 'common/components/Modal/SBModal';
import ClaimNowModal from './components/Modals/ClaimNowModal';
import {
  BoldButtonText,
  ModalCloseButton,
  ModalHeadingText,
  SubmitButton,
  SubmitButtonContent,
  Text,
} from 'modules/account/pages/components/MyCollectionCard/MyCollectionCard';
import Spacer from 'common/components/Spacer/Spacer';
import ExportBitsModal from 'modules/account/pages/components/MyCollection/ExportBitsModal';
import ConfirmBitTransferModal from 'modules/account/pages/components/MyCollection/ConfirmBitTransferModal';
import NFTOptionsModal from 'modules/account/pages/components/MyCollection/NFTOptionsModal';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import ArtistBenefitsPlaceholder from './ArtistBenefitsPlaceholder';
import PageLayout from 'common/components/PageLayout/PageLayout';
import ExportDetailsModal from 'modules/account/pages/components/MyCollection/ExportDetailsModal';

import EarIcon from 'common/icons/Ear.icon';
import ProfileRectangleIcon from 'common/icons/ProfileRectangle.icon';
import DiskArrowDownIcon from 'common/icons/DiskArrowDown.icon';
import ThreeDisksIcon from 'common/icons/ThreeDisks.icon';
import DollarCircleIcon from 'common/icons/DollarCircle.icon';
import SignedIcon from 'common/icons/Signed.icon';
import VideoMessageIcon from 'common/icons/VideoMessage.icon';
import RoyaltyIcon from 'common/icons/Royalty.icon';

import { QuickLinksContainer } from 'modules/account/pages/styled/MyCollection.styled';

import theme from 'theme/theme';
import { sm, xs } from 'utils/constants';
import { BenefitPerk, CollectionItemType } from 'services/types';
import { ExportModalType, NFTOptionsModalType } from 'modules/account/types';
import { useStoreActions } from 'store/store';

type BenefitStructureType = {
  leftIcon: JSX.Element;
  title: string;
  content: string | JSX.Element;
  buttons: any[];
};

type QueryParamsType = {
  id: string;
};

const quickLinks = [
  {
    title: 'Account Settings',
    link: '/account/settings',
  },
  {
    title: 'My Wallet',
    link: '/account/wallet',
  },
  {
    title: 'My Collection',
    link: '/account/my-collection',
  },
  {
    title: 'My Royalties',
    link: '/account/royalty',
  },
];

const ArtistBenefits = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const viewport = useViewport();
  //const [queryParams, setQueryParams] = useState<any>(null);

  //const state = useStoreState((state) => state.location.navigateState);
  //const setNavigateState = useStoreActions((actions) => actions.location.setNavigateState);

  const setBreadcrumbs = useStoreActions(
    (state) => state.location.setBreadcrumbs,
  );

  const queryParams = state as QueryParamsType;
  //const purchaseId = queryParams.id;

  const [loading, setLoading] = useState<boolean>(true);
  const [benefits, setBenefits] = useState<BenefitStructureType[]>([]);
  const [transactionData, setTransactionData] = useState<CollectionItemType>();

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showClaimNowModal, setShowClaimNowModal] = useState<boolean>(false);
  const [show2FARequiredModal, setShow2FARequiredModal] =
    useState<boolean>(false);
  const [showBlockchainModal, setShowNFTOptionsModal] = useState<{
    show: boolean;
    type: NFTOptionsModalType;
  }>({
    show: false,
    type: 'export',
  });
  const [showExportModal, setShowExportModal] = useState<{
    show: boolean;
    type: ExportModalType;
  }>({
    show: false,
    type: 'royalty',
  });
  const [showExportDetailsModal, setShowExportDetailsModal] = useState<{
    show: boolean;
    type: ExportModalType;
  }>({ show: false, type: 'royalty' });

  function getBenefitTitle(title: string) {

    if (title.toLowerCase() === 'mp3 download') {
      return 'song download'
    }
    if (title.toLowerCase() === 'quaterly royalties') {
      return 'earn song royalties'
    }
    if (title.toLowerCase() === 'unique numbered image') {
      return 'exclusive photo'
    }
    return title
  }

  useEffect(() => {
    //console.log("STATE", state);
    if (queryParams?.id) {
      //setQueryParams(state);
      //setNavigateState(null);
    } else {
      return navigate('/library/my-collection');
    }

    return () => {
      //setQueryParams(null);
    }
  }, [state]);

  useEffect(() => {
    if (!queryParams) return;
    let songName = ''
    restService
      .getCollectionFromId(queryParams.id)
      .then((data) => {
        if (!data?.length) return;
        const currentTransaction = data[0];
        songName = currentTransaction.title
        setTransactionData(currentTransaction);
      })
      .finally(() => {
        restService
          .getTransactionPerks(queryParams.id)
          .then((perks) => {
            const benefits = perks
              ?.sort((a, b) => a.order_number - b.order_number)
              .map((perk, index) => {
                const contentArr = perk.description.split('|');
                let content;
                if (contentArr.length > 1) {
                  content = (
                    <Content key={index}>
                      {contentArr.map((item, index) => {
                        if (index % 2 === 0) {
                          return <>{item}</>;
                        }
                        return (
                          <HighlightedContent
                            key={`item_${index}_${perk.perk_id}`}>
                            {item}
                          </HighlightedContent>
                        );
                      })}
                    </Content>
                  );
                } else {
                  content = <Content key={index}>{perk.description}</Content>;
                }

                return {
                  leftIcon: getBenefitIcon(perk),
                  title: getBenefitTitle(perk.title),
                  content,
                  buttons: (() => {
                    if (perk.type === 'royalty') {
                      return [
                        {
                          text: 'go to royalties',
                          onClick: () => {
                            navigate('/account/royalty');
                          },
                        },
                        {
                          className: 'inclusive-nft-blockchain-options-button',
                          text: 'blockchain options',
                          onClick: () =>
                            setShowNFTOptionsModal({
                              show: true,
                              type: 'export',
                            }),
                        },
                      ];
                    }

                    if (
                      perk.delivery_method === 'download' || perk.type === 'custom'
                    ) {

                      if (perk.type === 'mp3_download') {
                        return [
                          {
                            className: 'download-inclusive-mp3-button',
                            text: 'download now',
                            download_type: 'mp3_download',
                            onClick: () => {
                              
                            }
                          },
                        ];
                      }
                      
                      if (perk.type === 'nft_image') {
                        return [
                          {
                            className: 'download-inclusive-image-button',
                            text: 'download now',
                            download_type: 'image_download',
                            onClick: () => {
                              
                            },
                          },
                          {
                            className: 'inclusive-nft-image-blockchain-options-button',
                            text: 'blockchain options',
                            onClick: () =>
                              setShowNFTOptionsModal({
                                show: true,
                                type: 'image',
                              }),
                          },
                        ];
                      }
                      
                      return [
                        {
                          text: 'ability to claim coming soon',
                          onClick: () => {}, // setShowClaimNowModal(true)
                        },
                      ];
                    }

                    if (perk.delivery_method === 'credit') {
                      return [
                        {
                          text: 'ability to claim coming soon',
                          onClick: () => {}, // setShowClaimNowModal(true)
                        },
                      ];
                    }

                    return [];
                  })(),
                };
              });

            setBenefits(benefits ?? []);


          setBreadcrumbs([
            { value: 'account', label: 'My Account' },
            { value: 'library/my-collection/', label: 'My Collection' },
            { value: 'account/library/my-collection/' || '', label: songName },
            { value: 'benefits' || '', label: 'Benefits' },
          ]);

          })
          .finally(() => setLoading(false));
      });

    return () => {
      setBreadcrumbs([]);
    };
  }, [state]);


  function getBenefitIcon(perk: BenefitPerk) {
    switch (perk.type) {
      case 'nft_image':
        return <ProfileRectangleIcon />;
      case 'mp3_download':
        return <DiskArrowDownIcon />;
      case 'royalty':
        return <RoyaltyIcon />;
      default:
        if (perk.image_logo === 'tickets') {
          return <DollarCircleIcon />;
        } else if (perk.image_logo === 'signed') {
          return <SignedIcon />;
        } else if (perk.image_logo === 'video') {
          return <VideoMessageIcon />;
        } else if (perk.image_logo === 'content') {
          return <ThreeDisksIcon />;
        } else {
          return <EarIcon />;
        }
    }
  }

  const content = (
    <Wrapper>
      <ContentContainer>
        {loading ? (
          <ArtistBenefitsPlaceholder />
        ) : (
          <>
            <SongInfo>
              <SongImage src={transactionData?.file_url} />
              <SongDetails>
                <Typography
                  text={transactionData?.title || ''}
                  fontSize="fz24"
                  fontWeight="bold"
                  letterSpacing="-0.03em"
                />
                <Typography
                  text={transactionData?.artist_name || ''}
                  fontWeight="light"
                  letterSpacing="-0.03em"
                />
              </SongDetails>
            </SongInfo>

            {benefits.map((benefit, index, arr) => (
              <BenefitItem
                key={index}
                originaltransactionid={transactionData?.originaltransactionid}
                fileName={transactionData?.artist_name + ' - ' + transactionData?.title}
                leftIcon={benefit.leftIcon}
                title={benefit.title}
                content={benefit.content}
                buttons={benefit.buttons}
                hideBottom={index === arr.length - 1}
              />
            ))}
          </>
        )}
      </ContentContainer>

      {!loading && (
        <ButtonsContainer>
          <ButtonCustom
            label={<Typography text={'my collection'} fontWeight="bold" />}
            onClick={() => navigate('/library/my-collection')}
          />
        </ButtonsContainer>
      )}
    </Wrapper>
  );

  return (
    <PageContainer pageTitle='Included Benefits | SongBits'>
      <BreadcrumbsContainer>
        <MobileBreadcrumbs />
      </BreadcrumbsContainer>

      <PageLayout
        title={'included benefits'}
        padding={viewport.width < xs ? '20px 20px 0 20px' : '100px 20px 0 20px'}
        loading={loading}
        sections={[
          { content: content },
          {
            content:
              viewport.width > sm ? (
                <QuickLinksContainer>
                  {loading ? (
                    <QuickLinksPlaceholder />
                  ) : (
                    <QuickLinks links={quickLinks} isLogout />
                  )}
                </QuickLinksContainer>
              ) : null,
          },
        ]}
        is2ColumnLayout
      />

      <SBModal
        className={'export-details'}
        isOpen={showExportDetailsModal.show}
        height="553px"
        width="460px"
        content={
          <ExportDetailsModal
          contractAddress={
            (showExportDetailsModal.type === 'image'
              ? transactionData?.nft_image_collection
              : transactionData?.nft_collection) || ''
          }
            nftTokenId={showExportDetailsModal.type === 'image' ? String(transactionData?.nftimagetokenid) : String(transactionData?.nfttokenid)}
            type={showExportDetailsModal.type}
            artistName={transactionData?.artist_name || ''}
            songName={transactionData?.title || ''}
            bits={Number(transactionData?.data.number_of_bits).toLocaleString()}
            image={transactionData?.file_url || ''}
            handleClose={() => {
              setShowExportDetailsModal({
                show: false,
                type: 'royalty',
              });
            }}
          />
        }
      />

      <SBModal
        className={'claim'}
        width="410px"
        mobileWidth="400px"
        top="35%"
        isOpen={showClaimNowModal}
        content={<ClaimNowModal onClose={() => setShowClaimNowModal(false)} />}
      />
      <SBModal
        className={'nft-options'}
        isOpen={showBlockchainModal.show}
        height="553px"
        width="460px"
        top="30%"
        content={
          <NFTOptionsModal
            type={showBlockchainModal.type}
            onChange={() => {
              setShowNFTOptionsModal({
                show: false,
                type: 'export',
              });
            }}
            nft_link={transactionData?.nft_link || null}
            image_nft_link={transactionData?.nft_image_link}
            purchaseId={transactionData?.originaltransactionid || ''}
            fileName={
              transactionData?.title + ' - ' + transactionData?.artist_name
            }
            nftTokenId={transactionData?.nfttokenid || ''}
            nftImageTokenId={transactionData?.nftimagetokenid || ''}
            nft_image_available={transactionData?.nft_image_available || false}
          />
        }
      />
      <SBModal
        className={'mfa-required'}
        isOpen={show2FARequiredModal}
        height="429px"
        width="519px"
        content={
          <>
            <ModalCloseButton
              onClick={() => {
                setShow2FARequiredModal(false);
              }}>
              X
            </ModalCloseButton>
            <ModalHeadingText
              text="two factor authentication"
              fontWeight="bold"
              fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
            />
            <Spacer height={20} />

            <Text
              paddingRight={40}
              text="In order to protect your account, two factor authentication is required to export your bits to an external wallet. "
            />
            <Spacer height={25} />
            <Text
              paddingRight={20}
              text="Please visit your account settings to setup two factor authentication."
            />
            <Spacer height={40} />
            <SubmitButton
              width={172}
              borderRadius={50}
              height={45}
              fontSize={16}
              borderColor={theme.colors.white}
              label={
                <SubmitButtonContent justifyContent="center">
                  <BoldButtonText
                    text="account "
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                  <BoldButtonText
                    text="settings"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.yellow}
                  />
                </SubmitButtonContent>
              }
              onClick={() => {
                navigate('/account/settings');
              }}
            />
          </>
        }
      />
      <SBModal
        className={'export'}
        isOpen={showExportModal.show}
        height="553px"
        width="460px"
        content={
          <ExportBitsModal
            image={transactionData?.file_url || ''}
            songName={transactionData?.title || ''}
            artistName={transactionData?.artist_name || ''}
            bits={Number(
              transactionData?.data?.number_of_bits,
            ).toLocaleString()}
            type={showExportModal?.type}
            purchaseId={transactionData?.originaltransactionid || ''}
            reloadAPI={() => {}}
            onChange={(showConfirmModal) => {
              setShowExportModal({ show: false, type: 'royalty' });
              if (showConfirmModal) {
                setShowConfirmModal(true);
              }
            }}
            nftId={transactionData?.nftid || ''}
          />
        }
      />
      <SBModal
        className={'confirm'}
        isOpen={showConfirmModal}
        height="460px"
        width="527px"
        content={
          <ConfirmBitTransferModal
            onChange={() => {
              setShowConfirmModal(false);
            }}
            onExport={() => {
              setShowConfirmModal(false);
            }}
          />
        }
      />
    </PageContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

`;

export const SongInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 13px;
  margin-bottom: 20px;
`;

export const SongDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const SongImage = styled.img`
  width: 100px;
  height: 100px;

  border-radius: 10px;
`;

const ContentContainer = styled.div`

`;

const Content = styled.p`
  font-family: 'HKGrotesk-Light';
  line-height: 20px;
  font-weight: light;
  color: white;
`;

const HighlightedContent = styled.span`
  color: ${theme.colors.yellow};
  font-weight: bold;
  font-family: 'HKGrotesk-Black';
`;

const ButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  margin-top: 50px;
`;

const ButtonCustom = styled(Button)`
  width: 250px;
  height: 45px;

  white-space: nowrap;

  border: 3px solid ${theme.colors.yellow};
  border-radius: 50px;
`;

export default ArtistBenefits;
