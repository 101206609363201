import React, { useEffect, FC } from 'react';
import store, { useStoreState, useStoreActions } from 'store/store';

// import websocketClient from 'services/websocket.service';
// import authService from 'services/auth.service';
import graphService from 'services/graph.service';


const SystemServices: FC = ({  }) => {
  
  const currentVersion = useStoreState((state) => state.websocket.version?.full_build_number);
  const triggerReload = useStoreActions(
    (actions) => actions.websocket.triggerReload
  )
  
  const setup = async () => {

    const getState = (store_name: string) => {
      return (store.getState() as any)[store_name];
    }
    const navigate = (url: string, state?: Record<string, any>) => {
      store.getActions().location.navigate({url, state});
    }
    (window as any).getState = getState;
    (window as any).reactNavigate = navigate;
    
    window.addEventListener(
      "message",
      (event) => {
        const allowed = (process.env.REACT_APP_REST_URL || "").replace("https://", "").split("/")[0];
        if (event.origin !== `https://${allowed}`) return;
        if (event.data && event.data.source === "songbits" && event.data.threeDS) {
          store.getActions().purchase.setThreeDSecure(event.data.type);
        }
        if (event.data && event.data.source === "songbits" && event.data.threeDS_method) {
          store.getActions().purchase.setThreeDSecureMethod(event.data.type);
        }
      },
      false,
    );

    await graphService.init([
      `auth`,
    ]);
    //await authService.init();
    //await websocketClient.init(websocketURL);
  }

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    if (currentVersion !== undefined) {
      // current version has been set via init socket request, or version update while connected
      // check if we have a saved version
      const savedVersion = window.localStorage.getItem("songbits:version");
      if (savedVersion !== null) {
        // saved version exists, check if it matches current version
        if (savedVersion !== currentVersion) {
          // versions are different, save new current version and force reload
          // lets be nice and show a warning - the banner lasts for 5 seconds so we will refresh after that
          triggerReload();
        }
      } else {
        // we do not have a saved version, so save it for the next compare
        // this is probably initial site visit or first visit after this code goes live
        // this does not trigger a reload
        window.localStorage.setItem("songbits:version", currentVersion);
      }
    }
  }, [currentVersion]);

  return (
    <>
    </>
  );

};


export default SystemServices;
