import React, { useEffect, useState } from 'react';

import PageLayout from 'common/components/PageLayout/PageLayout';
import PageContainer from 'common/layout/PageContainer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Spacer from 'common/components/Spacer/Spacer';
import styled from 'styled-components';

import '../assets/editorSkins/songbitsskin/skin/skin.css';
import '../assets/editorSkins/songbitsskin/skin/content.css';
import Button from 'common/components/Button/Button';
import Dropdown from './components/Dropdown/Dropdown';
import restService from 'services/rest.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { useViewport } from 'use-viewport';
import { footerHeight, footerMobileHeight } from 'utils/constants';
import MessageListPlaceholder from 'common/components/Placeholder/MessageList';
import { useStoreActions } from 'store/store';

const quickLinks = [
  {
    title: 'Artist Profile',
    link: '/artist/settings',
  },
  {
    title: 'Manage Releases',
    link: '/artist/releases',
  },
  {
    title: 'Insights & Analytics',
    link: '/artist/insights',
  },
  /*{
    title: 'Accounting Management',
    link: '/artist/accounting/management',
  },
  {
    title: 'Payouts',
    link: '/artist/accounting/payouts',
  },*/
  {
    title: 'Artist Support (A&R)',
    link: '/support',
  },
  {
    title: 'Artist Dashboard',
    link: '/artist/dashboard',
  },
  {
    title: 'Marketing Tools',
    link: '/artist/marketing-tools',
  },
];

type MessageListType = {
  title: string;
  date: string;
  id: string;
  status: string;
  screenshot: string;
  user_count: number;
};

type DropdownItem = {
  value: string | number;
  label: string;
};

const MessageList = () => {
  const navigate = useNavigate();
  const viewport = useViewport();
  const location = useLocation();

  const { banner } = location.state as {
    banner: { title: string; text: string };
  };

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [loading, setLoading] = useState<boolean>(true);

  const [messageListData, setMessageListData] = useState<MessageListType[]>([]);
  setMessageListData;

  const MessageList_Dropdown = [
    {
      label: 'Draft',
      value: 'draft',
    },
    {
      label: 'Sent',
      value: 'sent',
    },
  ];

  const [currentFilter, setCurrentFilter] = useState<DropdownItem>(
    MessageList_Dropdown[0],
  );
  setCurrentFilter;

  function formatDate(date: string) {
    const values = date.split('T')[0].split('-');

    const new_date = new Date(
      parseInt(values[0].substring(0)),
      parseInt(values[1]) - 1,
      parseInt(values[2]),
    );

    return (
      values[2] +
      ' ' +
      new_date.toLocaleString('default', { month: 'long' }) +
      ' ' +
      values[0].substring(2)
    );
  }

  useEffect(() => {
    restService
      .getMessageList()
      .then((res) => {
        const messageList: MessageListType[] = [];
        res.forEach((message: any) => {
          messageList.push({
            title: message.subject,
            date: formatDate(message.created),
            id: message.id,
            status: message.status,
            screenshot: message.screenshot,
            user_count: message.user_count,
          });
        });
        setMessageListData(messageList);
        setLoading(false);
      })
      .catch(() => {
        setGlobalBanner({
          title: 'Error: ',
          text: 'Failed to retrieve message list',
        });
      });

    if (banner) {
      setGlobalBanner({
        title: banner.title,
        text: banner.text,
      });
    }
  }, []);

  function truncateLongTitle(title: string) {
    const characterLimit = viewport.width < 768 ? 16 : 20;

    if (title.length > characterLimit) {
      return title.substring(0, characterLimit + 1) + '...';
    } else {
      return title;
    }
  }

  const firstColumn = (
    <>
      <Dropdown
        data={MessageList_Dropdown}
        currentItem={currentFilter}
        onSelect={(item) => setCurrentFilter(item)}
      />

      <Spacer height={30} />

      {loading ? (
        <MessageListPlaceholder />
      ) : (
        <>
          {messageListData
            .filter((message) => message.status === currentFilter.value)
            .map((list, key) => {
              return (
                <MessageListContainer key={key}>
                  <MessageListItem>
                    <MessageListItemImage>
                      {list.screenshot && (
                        <MessageListImagePreview
                          src={`data:image/png;base64,${list.screenshot}`}
                        />
                      )}
                    </MessageListItemImage>
                    <MessageListItemDetails>
                      <MessageListHeaderText
                        text={`${list.user_count} user${
                          list.user_count !== 1 ? 's' : ''
                        }`}
                        fontWeight="bold"
                      />
                      <MessageListDetailText
                        text={`title: ${truncateLongTitle(list.title)}`}
                      />
                      <MessageListDetailText text={`date: ${list.date}`} />
                      <MessageListButtonContainer>
                        <Button
                          label={
                            <MessageListDetailText
                              text="edit"
                              fontWeight="bold"
                            />
                          }
                          onClick={() => {
                            navigate(
                              '/artist/insights/users/send-message/' + list.id,
                            );
                          }}
                        />
                        <MessageListDetailText text="|" fontWeight="bold" />
                        <Button
                          label={
                            <MessageListDetailText
                              text="preview"
                              fontWeight="bold"
                            />
                          }
                          onClick={() => {
                            navigate(
                              '/artist/insights/users/send-message/preview',
                              { state: { id: list.id, from: 'message-list' } },
                            );
                          }}
                        />
                        {/*<MessageListDetailText text="|" fontWeight="bold" />
                        <Button
                          label={
                            <MessageListDetailText
                              text="delete"
                              fontWeight="bold"
                            />
                          }
                          onClick={() => {}}
                        />*/}
                      </MessageListButtonContainer>
                    </MessageListItemDetails>
                  </MessageListItem>
                </MessageListContainer>
              );
            })}
        </>
      )}
    </>
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}>
      <PageLayout
        title="message list"
        is2ColumnLayout
        sections={[
          {
            content: firstColumn,
          },
          {
            content: <QuickLinks links={quickLinks} />,
          },
        ]}
      />
    </PageContainer>
  );
};

const MessageListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MessageListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 20px;
  align-items: center;

  @media (min-width: 768px) {
    gap: 25px;
  }
`;

const MessageListItemImage = styled.div`
  width: 145px;
  height: 145px;
  border: 1px solid ${theme.colors.yellow};
  border-radius: 20px;
`;

const MessageListItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MessageListButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  & > button {
    padding: unset;

    &:hover {
      background-color: unset;
    }
  }
`;

const MessageListHeaderText = styled(Typography)`
  font-size: 34px;
  color: ${theme.colors.yellow};

  @media (min-width: 768px) {
    font-size: 36px;
  }
`;

const MessageListDetailText = styled(Typography)`
  font-size: 18px;
  font-weight: 300;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const MessageListImagePreview = styled.img`
  width: fill-available;
  border-radius: 25px;
`;

export default MessageList;
