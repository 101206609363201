import * as React from 'react';

import { IconProps } from '../common.types';

const SongBitsLogos = ({ width = 53, height = 53 }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 53 53"
    xmlSpace="preserve"
    >
    <path d="M26.0215 52.0431C40.3928 52.0431 52.0431 40.3928 52.0431 26.0215C52.0431 11.6502 40.3928 0 26.0215 0C11.6502 0 0 11.6502 0 26.0215C0 40.3928 11.6502 52.0431 26.0215 52.0431Z" fill="#FFD600"/>
    <path d="M36.6172 17.7422L31.2633 12.3828C31.0172 12.1367 30.6836 12 30.3336 12H30V19H37V18.6664C37 18.3219 36.8633 17.9883 36.6172 17.7422ZM23.0219 30.375C22.043 30.375 21.25 31.0367 21.25 31.8516C21.25 32.6719 22.0484 33.3281 23.0273 33.3281C24.0062 33.3281 24.7992 32.6664 24.7992 31.8516C24.7992 31.0367 24.0008 30.375 23.0219 30.375ZM28.25 19.4375V12H24.7719V13.75H23.0219V12H17.3125C16.5852 12 16 12.5852 16 13.3125V38.6875C16 39.4148 16.5852 40 17.3125 40H35.6875C36.4148 40 37 39.4148 37 38.6875V20.75H29.5625C28.8406 20.75 28.25 20.1594 28.25 19.4375ZM21.2445 13.75H22.9945V15.5H21.2445V13.75ZM23.0109 34.75C21.1953 34.75 19.8391 33.0875 20.2 31.3102L21.2719 26V24.25H23.0219V22.5H21.2719V20.75H23.0219V19H21.2719V17.25H23.0219V15.5H24.7719V17.25H23.0219V19H24.7719V20.75H23.0219V22.5H24.7719V24.25H23.0219V26H24.2305C24.5422 26 24.8156 26.2242 24.8758 26.5305L25.8219 31.3266C26.1719 33.0984 24.8156 34.75 23.0109 34.75Z" fill="black"/>
</svg>
);

export default SongBitsLogos;
