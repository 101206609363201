import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme/theme';

import { sm } from 'utils/constants';

import TextInput from 'common/components/TextInput/TextInput';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../modules/library/pages/components/ModalLayout/ModalLayout';

import { ClipLoader as Loader } from 'react-spinners';

type ModalProps = {
  show: boolean;
};

const SSOLoadingModal = ({ show }: ModalProps) => {

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <SBModal
        className={'sso-loading'}
        isOpen={showModal}
        width={'390px'}
        mobileWidth={'100%'}
        height='100%'
        mobileTop="2%"
        withProceedingText={false}
        //top={viewport.width > xs ? '30%' : '20%'}
        content={
          <ModalLayout titleBottomMargin='15px' title={'logging you in'} >
            <ContentContainer className={'loading-modal'}>

              <div style={{marginTop: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '100%', justifyContent: 'center', height: '100%'}}>
                <Loader color={theme.colors.yellow} loading={true} size={60} />
              </div>

            </ContentContainer>
          </ModalLayout>
        }
      />

    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 40px;
    background-color: ${theme.colors.yellow50};
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Icon = styled.div<{ bottom?: number; left?: number }>`
  position: absolute;
  bottom: 23px;
  left: 8px;
  bottom: ${(props) => (props.bottom ? props.bottom : 23)}px;
  left: ${(props) => (props.left ? props.left : 8)}px;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    font-size: 14px;
    letter-spacing: -0.035em;

    padding-left: 32px;
    margin-top: 2px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > input {
      font-size: 18px;
    }
  }
`;

export default SSOLoadingModal;
