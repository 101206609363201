import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'store/store';
import { useViewport } from 'use-viewport';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';

import PageContainer from 'common/layout/PageContainer';
import EnokiSSO from '../components/sso/EnokiSSO';
import SSOLoadingModal from 'common/components/Auth/SSOLoading';

import theme from 'theme/theme';

import './styles/Login.css';

import {
  Form,
  Wrapper,
  HeadContent,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  StrapLineTypography,
  LoginContainer,
  LoginLink,
  StrapLineTypographyContainer,
  TextContent,
  TermsContainer,
  TermsLink
} from './styled/Login.styled';

import AccountBanner from '../assets/login.jpg';
import { xs } from 'utils/constants';


interface LocationState {
  from: {
    pathname: string;
  };
  banner: boolean;
}

const Login = () => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const routerState = useLocation().state as LocationState;
  const viewport = useViewport();
  const isAuth = useStoreState((state) => state.authentication.isAuthenticated);
  
  const [isSSO, setIsSSO] = useState<boolean>(false);


  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  useEffect(() => {
    if (isAuth) {
      navigate('/library/releases');
    }
  }, [isAuth]);

  /* Display unauth banner after redirect to login
   * Logout stores 'user_logout' in local storage. This
   * key/value is used to prevent unauth banner showing
   * on logout */
  useEffect(() => {
    if (
      routerState &&
      routerState.banner &&
      window.localStorage.getItem('user_logout') != 'logout'
    ) {
      window.localStorage.removeItem('user_logout');
      setGlobalBanner({
        title: 'Login required.',
        text: 'Please login below.',
      });
    } else {
      window.localStorage.removeItem('user_logout');
    }
  }, []);


  useEffect(() => {
    if (location.hash.indexOf("id_token") !== -1) {
      setIsSSO(true);
    }
  }, [location]);

  return (
    <PageContainer pageTitle="Login | SongBits">
      <ImageContainer>
        <Image src={AccountBanner} />
        <ImageGradient banner={AccountBanner} />
      </ImageContainer>
      <Wrapper>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <div style={{ display: 'flex' }}>
              <HeadContent
                text={viewport.width < xs ? 'Welcome' : 'welcome'}
                fontSize="fz100"
                fontWeight="bold"
              />
              &nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              {viewport.width < xs ? <>&nbsp;</> : <>&nbsp;&nbsp;</>}
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
              />
            </div>
          </StrapLineTypographyContainer>
        </HeadingSection>

        <Form>
          {isSSO ? (
            <TextContent
            text="Logging you in..."
            fontSize="fz24"
            fontWeight="bold"
            letterSpacing="-0.03em"
            fontColor={theme.colors.yellow}
          />
          ) : (
            <TextContent
              text="Login with"
              fontSize="fz24"
              fontWeight="bold"
              letterSpacing="-0.03em"
              fontColor={theme.colors.yellow}
            />
          )}

          <div style={{ display: 'flex', flexDirection: 'column', gap: '23px'  }} >

            <EnokiSSO showButtons={!isSSO} showEmail={true} />

          </div>

          <TermsContainer>
            By logging in, you agree to be bound by our{' '}
            <TermsLink className={'terms-link'} to="/legal/terms-conditions" target="_blank">
              terms and conditions.
            </TermsLink>
          </TermsContainer>

          <>
            <LoginContainer>
              No SongBits account?{'  '}
              <LoginLink className={'create-account-link'} to="/create-account">Create Account</LoginLink>
            </LoginContainer>
          </>  

        </Form>
      </Wrapper>
      <SSOLoadingModal show={isSSO} />
    </PageContainer>
  );
};

export default WithRouteAnimation(Login, false);
