import { useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { AuthResponse } from 'store/models/authModel/authModel.types';

import restService from 'services/rest.service';
import Button from 'common/components/Button/Button';
//import Typography from 'common/components/Typography/Typography';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
//import Email from 'common/icons/Email.icon';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';
import Slider1 from '../assets/confirm-email.jpg';

//import PasskeyRegister from '../components/passkey/PasskeyRegister';

import {
  //ChangeEmailButton,
  //FirstButton,
  HeadContainer,
  HeadContent,
  HeadingSection,
  //Icon,
  Image,
  ImageContainer,
  ImageGradient,
  StrapLineTypography,
  StrapLineTypographyContainer,
  TextConfirm,
  TextContent,
  TextContentConfirm,
} from './styled/ConfirmEmail.styled';

import {
  RESEND_VERIFICATION_EMAIL,
} from '../graphql/Mutations.graphql';
import {
  RESEND_VERIFICATION_EMAIL_MUTATION,
  RESEND_VERIFICATION_EMAIL_VARIABLES,
} from '../types';
import { useStoreActions, useStoreState } from 'store/store';

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, token_id } = useParams();
  const [searchParams] = useSearchParams();

  const getEvent = useStoreState((actions) => actions.websocket.getEvent);
  const emailConfirmationEvent = getEvent("email_confirmed");

  const emailAddress = useStoreState(
    (state) => state.authentication.emailAddress,
  );

  const isAuthenticated = useStoreState(
    (state) => state.authentication.isAuthenticated,
  );
  const suiEnabled = useStoreState((state) => state.authentication.auth.suiEnabled);

  const isArtist = useStoreState(
    (state) => state.authentication.auth.tokenData?.is_artist || false,
  );

  const redirectAfterAuth = useStoreState(
    (state) => state.authentication.auth.redirectAfterAuth,
  );

  const logout = useStoreActions((state) => state.authentication.logout);

  const setOnboarding = useStoreActions(
    (actions) => actions.authentication.setOnboarding,
  );
  
  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );
  const setEmailAddress = useStoreActions(
    (actions) => actions.authentication.setEmailAddress,
  );


  const confirmEmail = useStoreActions(
    (actions) => actions.authentication.confirmEmail,
  );


  const [isResentConfirmation, setIsResendConfirmation] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [stateToken, setStateToken] = useState<string>();
  const [fcaStep, setFcaStep] = useState<string | null>(null);
  //const [verificationSuccess, setVerificationSuccess] = useState<boolean>(false);



  const [resendVerificationEmail] = useMutation<
    RESEND_VERIFICATION_EMAIL_MUTATION,
    RESEND_VERIFICATION_EMAIL_VARIABLES
  >(RESEND_VERIFICATION_EMAIL, {
    variables: {
      expiredTokenId: token_id || stateToken,
      email: !(token_id || stateToken) ? email || null : null
    },
  });

  useEffect(() => {

    if (token) {
      if (searchParams.get('email')) {
        const email_ = searchParams.get('email');
        if (email_) {
          setEmailAddress(email_);
        }
        
      }
      verifyEmailAddress();
    }

    if (!email && isAuthenticated) {

      getAccountSettings().then((res) => {
        setEmail(res.email)
      });
    }

    if (emailAddress) {
      setEmail(emailAddress)
    }
  
    // Token from CreateAccount to resend conf email

    try {
      const stateToken_ = (location.state as any).tokenId;
      if (stateToken_) {
        setStateToken(stateToken_)
      }
      
    } catch {}
  
  }, []);

  useEffect(() => {
    // used for second browser window? do we prompt for paskey creation?...
    if (emailConfirmationEvent !== null) {
      if (emailConfirmationEvent.event.data.status === true) {
        setGlobalBanner({
          title: 'Email Confirmed!',
          text: 'Please login with your credentials below',
        });

        //logout();
        navigate('/login');
      }
    }
  }, [emailConfirmationEvent])

  const onSuccess = () => {
    const redirect = searchParams.get('redirect') || ''; // Check for redirect url param
    const navTo = (
      redirectAfterAuth === null ? redirect : (
        redirectAfterAuth.length > 0 ? redirectAfterAuth : redirect
      )
    );

    if (!isArtist) setOnboarding(fcaStep || '');
    if (!suiEnabled) {
      navigate('/account/settings');
    } else {
      navigate(
        navTo.length > 0 ? navTo : (
          isArtist ? '/artist/dashboard' : '/library/hot-releases'
        )
      );
    }
  }

  async function verifyEmailAddress() {
    if (!token) {
      await logout();
      return navigate("/");
    }
    await confirmEmail({ tokenValue: token }).then((response: AuthResponse) => {
      (window as any).gtag('event', 'sign_up_confirmed', {});

      setGlobalBanner({
        title: 'Email Confirmed!',
        text: '',
      });

      //setVerificationSuccess(true); // Remove Pass Key
      setFcaStep(response.fcaStep);
      onSuccess()
      
    }).catch((error: any) => {
      //console.log("CONFIRM EMAIL ERROR", error)
      if (
        error.data.message === 'Invalid token' ||
        error.data.message === 'Token expired'
      ) {
        setGlobalBanner({
          title: error.data.message + ': ',
          text: "Please click 'Resend Now'",
        });
      } else {
        setGlobalBanner({
          title: 'Error: ',
          text: 'Connecting to server',
        });
      }
    });
  }



  async function getAccountSettings() {
    const account = await restService.getAccountSettings().catch((error) => {
      error;
    });
    return account;
  }

  async function resendConfirmationHandler() {

    try {
      const response = await resendVerificationEmail();

      if (!response.errors) {
        if (response.data) {
          setIsResendConfirmation(true);
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  }
  return (
    <PageContainer pageTitle="Confim Email | SongBits">
      <ImageContainer>
        <Image src={Slider1} />
        <ImageGradient banner={Slider1} />
      </ImageContainer>
      <HeadContainer>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <HeadContent text="almost" fontSize="fz100" fontWeight="bold" />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div
              style={{
                display: 'inline-block',
              }}>
              <HeadContent
                text="there"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              <StrapLineTypography
                text="!"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
              />
            </div>
          </StrapLineTypographyContainer>
          {/*{(verificationSuccess ? 
            <PasskeyRegister 
              onClose={onSuccess}
            />
          :*/}
            <div>
              <TextContentConfirm
                text="Confirm Email Address"
                fontSize="fz18"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="-0.03em"
              />
              <div
                style={{
                  position: 'relative',
                  width: 350,
                }}>
                <TextConfirm
                  text="Please confirm your email address."
                  fontSize="fz18"
                  fontColor={theme.colors.white}
                />
                <TextConfirm
                  text="You will receive an email from us with a link to click. Check your email client. Remember to check your junk folder."
                  fontSize="fz18"
                  fontColor={theme.colors.white}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                  marginTop: '40px',
                  marginLeft: '5px',
                }}>
                <TextContent
                  text="Still Not arrived?"
                  fontSize="fz18"
                  fontColor={theme.colors.white}
                />
                <Button
                  className={'resend-verification-button'}
                  isTextBold={false}
                  label={
                    <TextContent text="Resend Now." fontSize="fz18" withUnderline />
                  }
                  onClick={resendConfirmationHandler}
                />
                {isResentConfirmation ? (
                  <TextContent text="Resent!" fontColor={theme.colors.yellow} />
                ) : null}
              </div>
            </div>
          {/*)}*/}
        </HeadingSection>
      </HeadContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(ConfirmEmail);
