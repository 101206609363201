import { State, action, thunkOn, thunk } from 'easy-peasy';
import store from 'store/store';

import { 
  AuthModel, 
  AuthResponse
} from './authModel.types';

import authService from 'services/auth.service';


class StoreError extends Error {
  data: Record<string, any> | Array<any>;
  constructor(message: string, data: Record<string, any> | Array<any>) {
    super(message);
    this.data = data;
  }
}

const AUTH_TEMPLATE: AuthResponse = {
  accessToken: null,
  tokenData: {
    role: null,
    exp: 0,
    user_id: null,
    email: null,
    is_admin: false,
    is_artist: false,
    created_at: null,
    username: null,
    iat: null,
    aud: null,
    iss: null,
  },
  isEmailConfirmed: false,
  redirectToGiveaway: false,
  redirectAfterAuth: null,
  kycStatus: null,
  fcaStep: null,
  suiEnabled: false,
}

export const authentication: AuthModel = {
  isReady: false,
  setReady: action((state) => {
    state.isReady = true;
    //console.log("IS READY = TRUE")
  }),
  register: {
    results: [
      {
        tokenId: null,
        auser: {
          id: null
        }
      }
    ]
  },
  loadingRegister: false,
  isIdentified: false,
  isAuthenticated: false,
  auth: {...AUTH_TEMPLATE},
  kyc: false,
  personalRisk: false,
  location: null,
  emailAddress: null,
  slug: null,
  ssoRedirect: null,
  dismissedSSOWarning: false,
  setRegisterData: action((state, payload) => {
    state.register = payload;
    state.isIdentified = true;
  }),
  setAuthData: action((state, payload) => {
    state.auth = payload;
    state.isIdentified = false;
    state.isAuthenticated = true;
  }),
  setEmailAddress: action((state, payload) => {
    state.emailAddress = payload;
  }),
  setSlug: action((state, payload) => {
    state.slug = payload;
  }),
  setSSORedirect: action((state, payload) => {
    state.ssoRedirect = payload;
  }),
  setLocation: action((state, payload) => {
    state.location = payload;
  }),
  setDismissedSSOWarning: action((state, payload) => {
    state.dismissedSSOWarning = payload;
  }),
  setFcaStep: action((state, payload) => {
    state.auth.fcaStep = payload;
  }),
  setKycStatus: action((state, payload) => {
    state.auth.kycStatus = payload;
  }),
  setOnboarding: action((state, payload) => {

    state.auth.fcaStep = payload;

    let kyc = false;
    if (payload === 'required' || payload === 'retry' || payload === null || payload === 'expired' || payload === 'in_flow') {
      if ((state.location === 'GB' || state.location === 'NI' || state.location === 'UK')) {
        kyc = true
      } else {
        kyc = false
      }
    } else {
      kyc = false
    }

    //
    state.kyc = kyc;
  }),
  setLogout: action((state) => {
    state.auth = {...AUTH_TEMPLATE}
    state.emailAddress = null;
    state.isAuthenticated = false;
    state.isIdentified = false
    state.slug = null;
    state.kyc = false;
    state.dismissedSSOWarning = false
  }),
  registerUser: thunk(async (actions, payload) => {
    const { data, error } = await authService.register(payload);
    if (!data) throw new StoreError("registerUser", error);
    actions.setRegisterData(data);
    return data;
  }),
  confirmEmail: thunk(async (actions, payload) => {
    const { data, error } = await authService.confirmEmail(payload.tokenValue);
    if (!data) throw new StoreError("confirmEmail", error);
    actions.setAuthData(data.auth);
    return data.auth;
  }),
  changeEmail: thunk(async (actions, payload) => {
    const { data, error } = await authService.changeEmail(payload);
    if (!data) throw new StoreError("changeEmail", error);
    //actions.setAuthData(data.auth);
    return data;
  }),
  authenticate: thunk(async (actions, payload) => {
    const { data, error } = await authService.authenticate(payload.username, payload.password);
    if (!data) throw new StoreError("authenticate", error);
    actions.setAuthData(data.auth);
    return data.auth;
  }),
  authenticateSSO: thunk(async (actions, payload) => {
    const { data } = authService.authenticateSSO(payload);
    if (!data) throw new StoreError("authenticate", {error: "SSO Failure"});
    actions.setAuthData(data.auth);
    return data.auth;
  }),
  getAuthToken: thunk(async (actions, _, { getState }) => {
    const state = getState() as State<AuthModel>;
    const token = state.auth.accessToken;
    if (token === null) return null;
    const current_time = Math.floor((Date.now()) / 1000);
    if (state.auth.tokenData.exp < (current_time - 10)) {
      actions.setLogout();
      return null;
    }
    return token;
  }),
  logout: thunk(async (actions) => {
    actions.setLogout();
    store.getActions().globalbanner.setGlobalBanner({
      title: 'Logged',
      text: 'Out!',
    });
    store.getActions().location.navigate("/login");
  }),
  onPushKYCStatus: thunkOn(
    (_, storeActions) => storeActions.websocket.pushKYCStatus,
    (actions, target) => {
      actions.setKycStatus(target.payload);
    }
  ),
  onPushFCAStatus: thunkOn(
    (_, storeActions) => storeActions.websocket.pushFCAStatus,
    (actions, target) => {
      actions.setOnboarding(target.payload);
    }
  ),
  onSSOVerifyPasskey: thunkOn(
    (_, storeActions) => storeActions.sso.verifyPasskey,
    (actions) => {
      const auth = store.getState().sso.auth;
      if (auth) {
        actions.setAuthData(auth);
      }
    }
  )
};
