import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';

import { escToClose } from 'utils/functions';

import theme from 'theme/theme';
import { NFTOptionsModalType } from 'modules/account/types';
import SuiVisionIcon from 'common/icons/MyCollection/SuiVision';
import EtherscanOutlinedIcon from 'common/icons/MyCollection/EtherscanOutlined.icon';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';


interface MyCollectionModalProps {
  onChange: () => void;
  nft_link: string | null;
  type: NFTOptionsModalType;
  purchaseId: string;
  fileName: string;
  nftTokenId: string | number | null;
  nftImageTokenId: string | number | null;
  nft_image_available: boolean;
  image_nft_link: string | undefined | null;
}

const NFTOptionsModal: FC<MyCollectionModalProps> = ({
  onChange,
  nft_link,
  type,
  nft_image_available,
  image_nft_link,
}) => {
  nft_image_available;

  const viewport = useViewport();

  useEffect(escToClose(handleClose), []);

  function handleClose() {
    onChange();
  }

  const modalContent = () => {
    if (type === 'export') {
      return (
        <ButtonContainer>
        {nft_link && nft_link.includes('suivision') ? (
          <IconButton
            className="view-on-blockchain-button"
            label={
              <>
                <SuiVisionIcon />{' '}
                <IconLabel text="view on Sui vision" fontSize="fz18" />
              </>
            }
            onClick={() => {
              window.open(nft_link, '_blank');
            }}
          />
        ) : (
          <></>
        )}
        {nft_link && !nft_link.includes('suivision') ? (
          <IconButton
            className="view-on-blockchain-button"
            label={
              <>
              <EtherscanOutlinedIcon width={55} height={55} />{' '}
              <IconLabel text="view on the blockchain" fontSize="fz18" />
              </>
            }
            onClick={() => {
              window.open(nft_link, '_blank');
            }}
          />
        ) : (
          <></>
        )}
        </ButtonContainer>
      );
    } else {
      return (
        <ButtonContainer>
          {image_nft_link && image_nft_link.includes('suivision') ? (
            <IconButton
              className="view-on-blockchain-button"
              label={
                <>
                  <SuiVisionIcon />{' '}
                  <IconLabel text="view on Sui vision" fontSize="fz16" />
                </>
              }
              onClick={() => {
                window.open(image_nft_link, '_blank');
              }}
            />
          ) : (
            <></>
          )}
          {image_nft_link && !image_nft_link.includes('suivision') ? (
            <IconButton
              className="view-on-blockchain-button"
              label={
                <>
                <EtherscanOutlinedIcon width={55} height={55} />{' '}
                <IconLabel text="view on the blockchain" fontSize="fz18" />
                </>
              }
              onClick={() => {
                window.open(image_nft_link, '_blank');
              }}
            />
          ) : (
            <></>
          )}
        </ButtonContainer>
      );
    }
  };

  return (
    <CustomComponentContainer id="nft-options-modal" className='nft-options-modal-content'>
      <CloseButton onClick={handleClose}>X</CloseButton>
      <BoldText
        text="blockchain options"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />

      <Spacer height={20} />
      <Typography
        text="Choose what you would like to do"
        fontWeight="light"
        fontColor={theme.colors.white}
      />
      <Spacer height={50} />

      {modalContent()}
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;

  @media (min-width: 576px) {
    letter-spacing: -3px;
  }
`;

const IconLabel = styled(Typography)`
  font-size: 18px;

  @media (min-width: 576px) {
    font-size: 18px;
  }
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 15px;
`;

const IconButton = styled(Button)<{ flexbasis?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 15px;
  font-size: 16px;
  padding: 0;

  :hover {
    background: transparent;
  }
`;

export default NFTOptionsModal;
