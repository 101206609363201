import { thunk, action } from 'easy-peasy';
import axios from 'services/axios.service';

import { DataModel } from './dataModel.types';

export const data: DataModel = {
  my_collection: [],
  ip_lookup: null,
  add_card: null,
  create_payment: null,
  rest: thunk(async (_, payload, { getState }) => {
    const matches = payload.url.match(/{([a-zA-Z0-9_]+)}/g);
    if (matches) {
      if (!payload.parameters || Object.keys(payload.parameters).length !== matches.length) {
        throw new Error("Missing REST URL Parameters");
      }
      Object.keys(payload.parameters).forEach(name => {
        if (payload.parameters) {
          payload.url = payload.url.replace(`{${name}}`, payload.parameters[name]);
        }
      })
    }
    if (!payload.method) {
      payload.method = (payload.data ? "POST" : "GET");
    }
    const { data } = await axios(Object.assign({
      url: payload.url,
      method: payload.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }, 
      (payload.data ? {data: payload.data} : {}), 
      (payload.headers ? {headers: payload.headers} : {})
    ));
    if (payload.model) {
      getState()[payload.model] = data;
    }
    return data;
  }),
  setAddCard: action((state, payload) => {
    state.add_card = payload;
  }),
  setCreatePayment: action((state, payload) => {
    state.create_payment = payload;
  })
}
