import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { useNavigate } from 'react-router-dom';
import { ClipLoader as Loader } from 'react-spinners';

import { useStoreState, useStoreActions } from 'store/store';
import theme from 'theme/theme';
import { sm } from 'utils/constants';
import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';
import Button from 'common/components/Button/Button';

import EnokiSSO from 'modules/auth/components/sso/EnokiSSO';


interface AccountSettingsSSOModalProps {
  isSSOReturn: boolean;
  isSSOComplete: boolean;
  ssoType?: string;
  isSSOError?: string;
  onSuccess: () => void;
  onHide: () => void;
}


const AccountSettingsSSOModal: FC<AccountSettingsSSOModalProps> = ({ onSuccess, onHide, isSSOReturn, isSSOComplete, ssoType, isSSOError }) => {

  const navigate = useNavigate()

  const { showSpace } = useIntercom();

  const { setSSORedirect } = useStoreActions((state) => state.authentication);

  const ssoRedirect = useStoreState((state) => state.authentication.ssoRedirect);

  useEffect(() => {

  }, [isSSOError])

  return (
    <ModalContainer>
      {(!isSSOReturn || isSSOComplete) && (
        <CloseButton onClick={onHide}>X</CloseButton>
      )}

      <Typography
        text={'simple sign on'}
        fontSize="fz48"
        fontWeight="bold"
      />

      <Spacer height={20} />

      {isSSOReturn && isSSOComplete ? (
        <>
        <div style={{display: 'flex', marginTop: '30px', flexDirection: 'column', gap: '30px'}}>
        <Typography
            text={'Setup Complete'}
            fontSize="fz18"
            lineHeight='24px'
            fontColor={theme.colors.yellow}
            fontWeight='bold'
            letterSpacing={'-3%'}
          />


          <div style={{display: 'inline'}}>

            <NormalTextInline>
              We have now setup your Simple Sign On. {'\n\n'} You will now be able to login to your account using your 
            </NormalTextInline>
            <BoldTextInline> {ssoType}</BoldTextInline>
            <NormalTextInline> login method.</NormalTextInline>

          </div>

          <Typography
              text={'You are now able to purchase.'}
              fontSize="fz18"
              lineHeight='24px'
              fontColor={theme.colors.white}
              fontWeight='regular'
            />

          <div style={{marginTop: '40px', }}>

            {ssoRedirect ? <>
              <CloseModalButton
                  height={45}
                  borderRadius={50}
                  borderColor={theme.colors.yellow}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.yellow}
                  className={'sso-required-modal-later-button'}
                  label={
                    <ButtonText
                      text="Continue"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={() => {
                    navigate(ssoRedirect)
                    setSSORedirect('')
                  }}
                />
            </> : <>
              <CloseModalButton
                  height={45}
                  borderRadius={50}
                  borderColor={theme.colors.yellow}
                  bgColor={theme.colors.black}
                  labelColor={theme.colors.yellow}
                  className={'sso-required-modal-later-button'}
                  label={
                    <ButtonText
                      text="close"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.yellow}
                    />
                  }
                  onClick={onHide}
                />
              </>}
            </div>

        </div>
      </>
      ) : (<></>)}

      {isSSOReturn && !isSSOComplete ? (
        <>
        <div style={{display: 'flex', marginTop: '30px', flexDirection: 'column', gap: '30px'}}>
          <Typography
            text={'We are just setting everything up it wont\ntake much longer.'}
            fontSize="fz18"
            lineHeight='24px'
            fontColor={theme.colors.white}
          />

          <LoaderContainer style={{marginTop: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', justifyContent: 'center', height: '100%'}}>
            <Loader color={theme.colors.yellow} loading={true} size={60} />
          </LoaderContainer>
        </div>
      </>
      ) : (<></>)}

      {!isSSOReturn && !isSSOComplete ? (
        <>
          <div style={{display: 'flex', gap: '30px', flexDirection: 'column', marginTop: '30px'}}>
            <Typography
              text={'Add an extra layer of security to your account protect it from unauthorized access. '}
              fontSize="fz18"
              lineHeight='24px'
              fontColor={theme.colors.white}
            />

            <Typography
              text={'Choose with method you prefer:'}
              fontSize="fz18"
              lineHeight='22px'
              fontColor={theme.colors.white}
            />
          </div>

          {isSSOError ? <>
          <ErrorText
              text={isSSOError}
              fontSize="fz18"
              lineHeight='22px'
              fontWeight='regular'
              fontColor={theme.colors.yellow}
            />
          </> : <></>
          }

          <div style={{ display: 'flex', flexDirection: 'column', gap: '23px', marginTop: '30px' }} >
            <EnokiSSO myAccount={true} showButtons={!isSSOReturn} showEmail={false} onComplete={onSuccess} />
          </div>

          {isSSOError ? <>

            <div style={{marginTop: '40px', }}>
            <SupportButton
                height={45}
                borderRadius={50}
                borderColor={theme.colors.white}
                bgColor={theme.colors.black}
                labelColor={theme.colors.white}
                className={'sso-contact support'}
                label={
                  <ButtonText
                    text="Contact Support"
                    fontSize="fz16"
                    fontWeight="bold"
                    fontColor={theme.colors.white}
                  />
                }
                onClick={() => {
                  showSpace('messages')
                }}
              />
              </div>
          </> : <></>}
        </>
      ) : (<></>)}


    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;


const NormalTextInline = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  white-space: pre-line;
  display: inline;
`;

const BoldTextInline = styled.p`
  font-family: HKGrotesk-Black;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.03em;
  display: inline;
`;


const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const ErrorText = styled(Typography)`
  padding-top: 30px;
`;  

const CloseModalButton = styled(Button)<{ width?: number }>`
  margin: 0 auto;
  width: 100%;
  border-width: 1px;

  position: relative;
`;

const SupportButton = styled(Button)<{ width?: number }>`
  width: 283px;
  border-width: 1px;

  position: relative;
`;

const LoaderContainer = styled.div`
  width: 100%;

  @media (min-width: ${sm}px) {
    width: 80%;
  }
`;

export default AccountSettingsSSOModal;
