import * as React from 'react';

import { IconProps } from '../common.types';

const SSOGoogle = ({
  width = 22,
  height = 22,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M21.56 11.25C21.56 10.47 21.49 9.72 21.36 9H11V13.255H16.92C16.665 14.63 15.89 15.795 14.725 16.575V19.335H18.28C20.36 17.42 21.56 14.6 21.56 11.25Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11 22C13.97 22 16.46 21.015 18.28 19.335L14.725 16.575C13.74 17.235 12.48 17.625 11 17.625C8.13504 17.625 5.71004 15.69 4.84504 13.09H1.17004V15.94C2.98004 19.535 6.70004 22 11 22Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M4.845 13.0899C4.625 12.4299 4.5 11.7249 4.5 10.9999C4.5 10.2749 4.625 9.56994 4.845 8.90994V6.05994H1.17C0.425 7.54494 0 9.22494 0 10.9999C0 12.7749 0.425 14.4549 1.17 15.9399L4.845 13.0899Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11 4.375C12.615 4.375 14.065 4.93 15.205 6.02L18.36 2.865C16.455 1.09 13.965 0 11 0C6.70004 0 2.98004 2.465 1.17004 6.06L4.84504 8.91C5.71004 6.31 8.13504 4.375 11 4.375Z" fill="white"/>
  </svg>
);

export default SSOGoogle;
